define('modules/campaign/views/general',['require','Underscore','jQuery','models/advertiser','text!modules/campaign/templates/advertiserFreqCap.html','models/audienceVerification','utils/CampaignStrategyUtils','modules/campaign/models/currencySearch','text!modules/campaign/templates/goal.html','text!templates/info_box.html','text!modules/shared/templates/safeSupply.html','models/organization','models/sessionUser','T1','T1Alert','T1Layout','T1Permissions','T1Timezones','T1View','text!modules/campaign/templates/general.html','T1UtilsAsync','utils/LaunchDarklyUtils','collections/vendors','jQueryUI','T1Accordion','T1DatePicker','T1Form','T1Notifier','T1Selectbox','T1Tooltip','T1Truncate'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Advertiser = require("models/advertiser");
  const advertiserFreqCapTemplate = require("text!modules/campaign/templates/advertiserFreqCap.html");
  const AudienceVerificationModel = require("models/audienceVerification");
  const CampaignStrategyUtils = require("utils/CampaignStrategyUtils");
  const CurrencySearch = require("modules/campaign/models/currencySearch");
  const goalTemplate = require("text!modules/campaign/templates/goal.html");
  const info_box_template = require("text!templates/info_box.html");
  const SafeSupply = require("text!modules/shared/templates/safeSupply.html");
  const OrganizationModel = require("models/organization");
  const SessionUser = require("models/sessionUser");
  const T1 = require("T1");
  const T1Alert = require("T1Alert");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1Timezones = require("T1Timezones");
  const T1View = require("T1View");
  const template = require("text!modules/campaign/templates/general.html");
  const utilsAsync = require("T1UtilsAsync");
  const LaunchDarklyUtils = require("utils/LaunchDarklyUtils");
  const Vendors = require("collections/vendors");
  const jQueryUI = require("jQueryUI");
  const T1Accordion = require("T1Accordion");
  const T1DatePicker = require("T1DatePicker");
  const T1Form = require("T1Form");
  const T1Notifier = require("T1Notifier");
  const T1Selectbox = require("T1Selectbox");
  const T1Tooltip = require("T1Tooltip");
  const T1Truncate = require("T1Truncate");
  let goalValue, originalVendorContracts, prevGoalType, selectCurrCode;
  const twoDigits = 2;
  const animSpeed = 200;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  const overrideLevels = Object.freeze({
    0: "STL_NONE",
    25: "STL_LIGHT",
    50: "STL_MODERATE",
    75: "STL_STRONG",
    100: "STL_MAXIMUM"
  });
  const canEditMargins = T1.Utils.valueToBool(SessionUser.getUser().get("edit_margins_and_performance"));
  const useNdarPermitted = T1Permissions.check("feature", "reporting_data_export_audience_verification");
  var useViewabilityReportingPermitted = T1Permissions.check("feature", "use_viewability_reporting");
  var moatAutoTagPermitted = T1Permissions.check("feature", "moat_auto_tag");
  var useMTALite = T1Permissions.check("feature", "use_mta_lite");
  var useAllocationPacing = T1Permissions.check("feature", "use_alloc_pacing");
  var isCookielessHidden = T1Permissions.check("feature", "hide_cookieless");
  var defaultViewabilityVendorID = "1110";
  var moatVendorID = "1078";
  var frequencyIntervalText = {
    hour: "Hour",
    day: "Day",
    week: "7 Days",
    month: "30 Days"
  };
  var goalTypeText = {
    spend: "CPM SPEND",
    reach: "CPM REACH",
    vcpm: "Viewable CPM",
    viewability_rate: "Viewability Rate",
    vcr: "Video Completion Rate",
    ctr: "CTR",
    cpc: "CPC",
    cpa: "CPA",
    roi: "ROI"
  };
  var goalTypes = [];
  var dropdownList = {
    frequency_type: {
      options: [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }, {
        value: "no-limit",
        text: "No Cap"
      }]
    },
    frequency_interval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }, {
        value: "week",
        text: frequencyIntervalText.week
      }, {
        value: "month",
        text: frequencyIntervalText.month
      }]
    },
    meritEventOptions: {
      options: [{
        value: "variable",
        text: "Attribute one Merit Event per user per"
      }, {
        value: "every",
        text: "Attribute all Merit Events"
      }]
    },
    attrConversionOptions: {
      options: [{
        value: "days",
        text: "Days"
      }, {
        value: "hours",
        text: "Hours"
      }]
    },
    attrConversionWithMinOptions: {
      options: [{
        value: "days",
        text: "Days"
      }, {
        value: "hours",
        text: "Hours"
      }, {
        value: "minutes",
        text: "Minutes"
      }]
    },
    daily_spend_cap: {
      options: [{
        value: "auto",
        text: "Auto"
      }, {
        value: "manual",
        text: "Manual"
      }]
    },
    monetary_pacing: {
      options: [{
        value: "asap",
        text: "ASAP"
      }, {
        value: "even",
        text: "Even"
      }, {
        value: "no-limit",
        text: "No Cap"
      }]
    },
    impression_pacing: {
      options: [{
        value: "asap",
        text: "ASAP"
      }, {
        value: "even",
        text: "Even"
      }, {
        value: "no-limit",
        text: "No Cap"
      }]
    },
    daily_monetary_cap: {
      options: [{
        value: "auto",
        text: "Auto"
      }, {
        value: "manual",
        text: "Manual"
      }]
    },
    daily_impression_cap: {
      options: [{
        value: "auto",
        text: "Auto"
      }, {
        value: "manual",
        text: "Manual"
      }]
    },
    nielsen_gender: {
      options: [{
        value: "1000",
        text: "Female"
      }, {
        value: "2000",
        text: "Male"
      }]
    },
    nielsen_ages_female: {
      options: [{
        value: "1000",
        text: "13 - 17"
      }, {
        value: "1003",
        text: "18 - 20"
      }, {
        value: "1004",
        text: "21 - 24"
      }, {
        value: "1005",
        text: "25 - 29"
      }, {
        value: "1006",
        text: "30 - 34"
      }, {
        value: "1007",
        text: "35 - 39"
      }, {
        value: "1008",
        text: "40 - 44"
      }, {
        value: "1009",
        text: "45 - 49"
      }, {
        value: "1010",
        text: "50 - 54"
      }, {
        value: "1011",
        text: "55 - 64"
      }, {
        value: "1012",
        text: "65+"
      }]
    },
    nielsen_ages_male: {
      options: [{
        value: "2000",
        text: "13 - 17"
      }, {
        value: "2003",
        text: "18 - 20"
      }, {
        value: "2004",
        text: "21 - 24"
      }, {
        value: "2005",
        text: "25 - 29"
      }, {
        value: "2006",
        text: "30 - 34"
      }, {
        value: "2007",
        text: "35 - 39"
      }, {
        value: "2008",
        text: "40 - 44"
      }, {
        value: "2009",
        text: "45 - 49"
      }, {
        value: "2010",
        text: "50 - 54"
      }, {
        value: "2011",
        text: "55 - 64"
      }, {
        value: "2012",
        text: "65+"
      }]
    },
    nielsen_country_code: {
      options: [{
        value: "AE",
        text: "AE"
      }, {
        value: "AU",
        text: "AU"
      }, {
        value: "BE",
        text: "BE"
      }, {
        value: "BG",
        text: "BG"
      }, {
        value: "BR",
        text: "BR"
      }, {
        value: "CA",
        text: "CA"
      }, {
        value: "CZ",
        text: "CZ"
      }, {
        value: "DE",
        text: "DE"
      }, {
        value: "ES",
        text: "ES"
      }, {
        value: "FR",
        text: "FR"
      }, {
        value: "GB",
        text: "GB"
      }, {
        value: "GR",
        text: "GR"
      }, {
        value: "HK",
        text: "HK"
      }, {
        value: "HU",
        text: "HU"
      }, {
        value: "ID",
        text: "ID"
      }, {
        value: "IE",
        text: "IE"
      }, {
        value: "IL",
        text: "IL"
      }, {
        value: "IN",
        text: "IN"
      }, {
        value: "IT",
        text: "IT"
      }, {
        value: "JP",
        text: "JP"
      }, {
        value: "MX",
        text: "MX"
      }, {
        value: "MY",
        text: "MY"
      }, {
        value: "NL",
        text: "NL"
      }, {
        value: "NO",
        text: "NO"
      }, {
        value: "NZ",
        text: "NZ"
      }, {
        value: "PH",
        text: "PH"
      }, {
        value: "PL",
        text: "PL"
      }, {
        value: "PR",
        text: "PR"
      }, {
        value: "SG",
        text: "SG"
      }, {
        value: "TH",
        text: "TH"
      }, {
        value: "TR",
        text: "TR"
      }, {
        value: "TW",
        text: "TW"
      }, {
        value: "US",
        text: "US"
      }, {
        value: "ZA",
        text: "ZA"
      }]
    }
  };
  var Date = T1.Date;
  var useCampaignFreqCapTester = CampaignStrategyUtils.useCampaignFrequencyCap;
  var getStartEndDatesDisplay = CampaignStrategyUtils.getDisplayTextForStartAndEndDates;
  var useCampaignDailySpendCapTester = CampaignStrategyUtils.useCampaignDailySpendCap;
  var getCtdReportJsonObjByModel = CampaignStrategyUtils.getCtdReportJsonObjByModel;
  var formatsRolledUpNumbersNoAbbreviation = CampaignStrategyUtils.formatsRolledUpNumbersNoAbbreviation;
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  var getCurrencyList = T1.Utils.getCurrencyList;
  var goalTypePCT = ["ctr", "vcr", "viewability_rate"];
  const defaultAnimationSpeed = 300;
  for (const thisGoalType in goalTypeText) {
    if (T1.Utils.hasOwnProp(goalTypeText, thisGoalType)) {
      goalTypes.unshift({
        value: thisGoalType,
        text: goalTypeText[thisGoalType]
      });
    }
  }
  function getSelectedMeritValue(meritValue) {
    const values = dropdownList.meritEventOptions.options.map(option => option.value);
    var index = values.indexOf(meritValue);
    index = index !== -1 ? index : 0;
    return values[index];
  }
  function getOrgModelFetchObject(view) {
    return {
      success: function () {
        if (view.model.get("id") && useNdarPermitted) {
          view.nielsenDemographicsModel.fetch(getNielsenDemographicsObject(view), view.id);
        } else {
          view.vendorList.pageLimit = 100;
          view.vendorList.search.set(getSearchSetObject(view));
        }
      }
    };
  }
  function getNielsenDemographicsObject(view) {
    return {
      success: function (data) {
        view.setGenderSelections(data && data.data.providers.nielsen && data.data.providers.nielsen.demographics, data.data.providers.nielsen.primary_country, data.data.active);
        view.vendorList.pageLimit = 100;
        view.vendorList.search.set(getSearchSetObject(view));
      },
      onError: function (data) {
        if (data.errors[0].message === "campaign not found") {
          view.setGenderSelections([], "", false);
          view.vendorList.pageLimit = 100;
          view.vendorList.search.set(getSearchSetObject(view));
        }
      }
    };
  }
  function getSearchSetObject(view) {
    return {
      params: [{
        term: "VIEWABILITY",
        searchField: "vendor_type"
      }],
      fetchRequired: true,
      pageLimit: 100,
      success: function () {
        view.isCustomBrainAllowed = T1Permissions.check("feature", "campaign.custom_brain_selections");
        view.suspicious_traffic_level_default = true;
        view.ensureDefaultCurrencyCode();
        view.budgetFlightFlag = true;
        view.load();
        view.loadLayouts();
      }
    };
  }
  function getNDARFormValues(acc, [key, val]) {
    if (key.startsWith("nielsen_")) {
      acc[key] = val;
    }
    return acc;
  }
  function convertMinutesToRange(minuteValue, decimalCount) {
    let selectValue, val;
    const hourInMinutes = 60;
    const dayInHours = 24;
    var minutes = parseFloat(minuteValue);
    var hours = minutes / hourInMinutes;
    var days = hours / dayInHours;
    if (decimalCount !== 0) {
      if (days >= 1 && hours % dayInHours === 0) {
        selectValue = "days";
        val = days;
      } else if (minutes > 1) {
        selectValue = "hours";
        val = hours;
      } else {
        selectValue = "minutes";
        val = minutes;
      }
    } else {
      if (days >= 1 && hours % dayInHours === 0) {
        selectValue = "days";
        val = days;
      } else if (hours >= 1 && minutes % hourInMinutes === 0) {
        selectValue = "hours";
        val = hours;
      } else {
        selectValue = "minutes";
        val = minutes;
      }
    }
    return {
      selectValue: selectValue,
      rangeValue: isNaN(val) ? "" : val.toFixed(decimalCount)
    };
  }
  function updateBreadCrumb(isReadOnly, campaign) {
    var pathPieces = [{
      label: campaign.name,
      location: (isReadOnly ? "#campaign/readonly/" : "#campaign/edit/") + campaign.id
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces,
      headerInfo: CampaignStrategyUtils.getBreadCrumbHeaderInfo(campaign)
    });
  }
  function updateBreadCrumbNewT1(isReadOnly, campaign) {
    const {
      origin: origin,
      organization: orgNewT1
    } = window.hybridWorkflowParams;
    const locations = [{
      label: "Campaigns Home",
      location: `${origin}/campaigns?organization=${orgNewT1}`,
      target: "_top"
    }];
    if (campaign.id) {
      locations.push({
        label: campaign.name,
        location: (isReadOnly ? "#campaign/readonly/" : "#campaign/edit/") + campaign.id
      });
    } else {
      locations.push({
        label: "New Campaign",
        location: "",
        supressLink: true
      });
    }
    T1.EventHub.publish("updateNavPathNewT1", {
      headerInfo: CampaignStrategyUtils.getBreadCrumbHeaderInfo(campaign),
      locations: locations
    });
  }
  const CampaignGeneralDetailsView = T1View.extend({
    template: template,
    orgModel: null,
    status: 1,
    displayDateFormat: "MMMM d, yyyy",
    displayTimeFormat: "h:mm tt",
    startDate: null,
    endDate: null,
    startTime: "12:00 AM",
    endTime: "11:59 PM",
    zoneName: null,
    startBuffer: 7,
    endBuffer: 30,
    startBufferMin: {
      minutes: 10
    },
    useCampaignFreqCap: false,
    useCampaignDailySpend: false,
    showCurrentFlights: false,
    isProgrammaticGuaranteed: false,
    partials: {
      advertiserFreqCapSection: advertiserFreqCapTemplate,
      goal: goalTemplate,
      info_box: info_box_template,
      safeSupply: SafeSupply
    },
    eventhubEvents: {
      "pixel:showDetails": function () {
        function onPixelListLoad() {
          this.$(".merit-pixels-list").slideDown(defaultAnimationSpeed).end().find(".form-select.pixel-select select").attr("disabled", "disabled").trigger("liszt:updated");
        }
        this.pixelList.load().then(onPixelListLoad.bind(this));
      },
      "pixel:hideDetails": function () {
        this.$(".merit-pixels-list").slideUp(defaultAnimationSpeed);
        this.pixelList.unload();
        this.$(".form-select.pixel-select select").removeAttr("disabled").trigger("liszt:updated");
      },
      "advertiser:select": function (data) {
        this.advertiserName = data.name;
        this.onAdvertiserChange(data);
      },
      "currencyCode:change": "doCurrencySelectChange",
      "timeZoneName:change": function (timeZoneName) {
        this.zoneName = timeZoneName;
        this.preferences.save();
      },
      "selected:pixel": "meritPixelChangedHandler",
      "pixelBundle:select": "meritPixelChangedHandler",
      "budgetFlight:budgetChange": "onBudgetFlightChange"
    },
    onAdvertiserChange: function (data) {
      var self = this;
      const $minimizeSection = this.$(".minimize-ad");
      var $checkbox = $minimizeSection.find(".checkbox");
      var $advLb = $minimizeSection.find(".advertiser-ad-collision");
      const advertiser = new Advertiser({
        id: data.id
      });
      var advertiserTemplateData = {
        advertiserFreqCap: false
      };
      function onAdvertiserSuccess(model) {
        if (self.isProgrammaticGuaranteed) {
          $checkbox.prop("disabled", true);
          $checkbox.prop("checked", false);
        } else if (model && model.get("minimize_multi_ads") === "1") {
          $checkbox.prop("disabled", true);
          $checkbox.prop("checked", true);
          $advLb.text("(This setting has been activated at the Advertiser level)");
        } else {
          $checkbox.prop("disabled", false);
          $checkbox.prop("checked", false);
          $advLb.text("");
        }
        const advertiserJSON = advertiser.toJSON();
        self.renderPartialTemplate({
          template: SafeSupply,
          useTemplateHtml: false,
          action: "html",
          targetEl: ".safe-supply-section",
          data: {
            isProgrammaticGuaranteed: self.isProgrammaticGuaranteed,
            ads_txt_verified: self.isProgrammaticGuaranteed ? "NONE" : "INHERITED",
            inherited_ads_txt_verified_origin_entity_type: "Advertiser"
          }
        });
        if (advertiserJSON.id && advertiserJSON.frequency_type !== "no-limit") {
          advertiserTemplateData = {
            advertiserFreqCap: true,
            advertiserFrequencyType: advertiserJSON.frequency_type,
            advertiserFrequencyAmount: advertiserJSON.frequency_amount,
            advertiserFrequencyInterval: advertiserJSON.frequency_interval
          };
        }
        self.renderPartialTemplate({
          template: advertiserFreqCapTemplate,
          useTemplateHtml: false,
          action: "html",
          targetEl: ".advertiser-freq-cap-section",
          data: advertiserTemplateData
        });
      }
      if (!(this.model && this.model.id) && this.advertiserName) {
        advertiser.fetch({
          success: onAdvertiserSuccess
        });
      }
    },
    meritPixelChangedHandler: function (args) {
      if (this.el.parent().parents().find(".campaignDuplicateModal")) {
        return;
      }
      this.isFormElementsChanged = true;
      if (this.hasStarted) {
        const idNew = args && args.id ? args.id.toString() : "";
        let idOld = this.model.get("merit_pixel_id");
        if (!idOld) {
          idOld = "";
        }
        if (idNew.toLowerCase() === idOld.toLowerCase()) {
          this.$(".attribution .info-box").slideUp(defaultAnimationSpeed);
        } else {
          this.$(".attribution .info-box").slideDown(defaultAnimationSpeed);
        }
      }
    },
    prepareDatePicker: function () {
      var endDate = new Date();
      var startDate = this.startDate || new Date();
      var campaign = this.model;
      if (this.model.id) {
        startDate = Date.parse(campaign.get("start_date"));
        endDate = Date.parse(campaign.get("end_date"));
        this.zoneName = campaign.get("zone_name");
        this.startTime = startDate.toString(this.displayTimeFormat);
        this.endTime = endDate.toString(this.displayTimeFormat);
      } else {
        startDate.addDays(this.startBuffer);
        endDate.addDays(this.endBuffer);
        endDate = endDate.at(this.endTime);
        startDate = startDate.at(this.startTime);
      }
      this.endDate = endDate;
      this.startDate = startDate;
    },
    initialize: function ({
      model: model
    }) {
      this.useViewabilityReportingPermitted = useViewabilityReportingPermitted;
      this.useProgrammaticGuaranteedFlag = T1Permissions.check("feature", "programmatic_guaranteed_campaign");
      this.useDynamicBudgetAllocation = T1Permissions.check("feature", "dynamic_budget_allocation");
      this.useMTALite = useMTALite;
      this.showPGAlert = true;
      this.isVendorAllowed = {
        1110: true,
        1078: T1Permissions.check("feature", "enable_viewability_moat"),
        1109: useViewabilityReportingPermitted
      };
      this.currencySearch = new CurrencySearch();
      this.preferences.mark(["zoneName", "showCurrentFlights"]);
      this.model = model;
      this.vendorList = new Vendors();
      this.originalRestrictTpas = this.model.get("restrict_targeting_to_deterministic_id");
      this.originalSameDevice = this.model.get("restrict_targeting_to_same_device_id");
      this.originalSuspiciousOverride = this.model.get("override_suspicious_traffic_filter");
      this.originalSuspiciousLevel = this.model.get("suspicious_traffic_filter_level");
      this.originalGoalType = this.model.get("goal_type");
      this.originalViewabilityReportingPermitted = this.model.get("viewability_type");
      this.originalVendorId = this.model.get("viewability_vendor_id");
      this.originalViewabilityType = this.model.get("viewability_type");
      this.originalViewabilityRate = this.model.get("viewability_sample_rate");
      originalVendorContracts = this.model.get("vendor_contract");
      this.id = this.model ? this.model.id : null;
      this.isNewCampaign = this.id === undefined;
      this.hasStarted = CampaignStrategyUtils.hasEntityStarted(this.model);
      this.canSetCurrency = true;
      this.nielsenDemographicsModel = new AudienceVerificationModel({
        campaignId: this.id,
        provider: "nielsen"
      });
      this.model.getOrgId().then(orgId => {
        this.orgId = orgId;
        this.orgModel = new OrganizationModel({
          id: this.orgId
        });
        this.orgModel.fetch(getOrgModelFetchObject(this));
      });
    },
    unload: function () {
      this.suspicious_traffic_level_default = true;
      if (this.budgetFlightsLayout) {
        this.budgetFlightsLayout.unload();
      }
      if (this.advertiserSelect) {
        this.advertiserSelect.unload();
      }
      if (this.adServerSelect) {
        this.adServerSelect.unload();
      }
      if (this.pixelSelect) {
        this.pixelSelect.unload();
      }
      if (this.pixelList) {
        this.pixelList.unload();
      }
      if (this.vendorAgreements) {
        this.vendorAgreements.unload();
      }
      this.$("strand-tooltip").each(function (i, tooltip) {
        tooltip.close();
      });
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    loadLayouts: function () {
      var model = this.model;
      this.adServerModel = {
        id: model.get("ad_server_id") || null
      };
      if (this.isNewCampaign) {
        this.advertiserSelect = new T1Layout({
          el: () => this.el,
          selectorPath: ".advertiser-select",
          template: '<div class="advertiser-select-box"></div>',
          layout: {
            ".advertiser-select-box": [{
              module: "advertiser",
              viewType: "select",
              options: {
                model: model.get("advertiser") || null
              }
            }]
          }
        });
      }
      this.adServerSelect = new T1Layout({
        el: () => this.el,
        selectorPath: ".adServer-select",
        template: '<div class="adServer-select-box"></div>',
        layout: {
          ".adServer-select-box": [{
            module: "adServer",
            viewType: "select",
            options: {
              model: this.adServerModel
            }
          }]
        }
      });
      this.vendorAgreements = new T1Layout({
        el: () => this.el,
        selectorPath: ".vendor-agreements",
        template: '<div class="vendor-wrapper" data-bind="hidden"></div>',
        layout: {
          ".vendor-wrapper": [{
            module: "vendor",
            viewType: "list",
            options: {
              collection: model.get("vendor_contract"),
              currencyCode: model.get("currency_code")
            }
          }]
        }
      });
      this.pixelList = new T1Layout({
        el: () => this.el,
        selectorPath: ".merit-pixels-list",
        template: '<div class="pixel-list-box"></div>',
        layout: {
          ".pixel-list-box": [{
            module: "pixel",
            viewType: "list",
            options: {
              orgId: this.orgId,
              getAdvertiserId: () => this.$('[data-bind="advertiser_id"]').attr("value"),
              selectionMode: "singleSelection"
            }
          }]
        }
      });
      this.budgetFlightsLayout = new T1Layout({
        el: () => this.$(".budget-flight-holder"),
        template: '<div class="budget-flight-box"></div>',
        layout: {
          ".budget-flight-box": [{
            module: "campaign",
            viewType: "budgetFlights",
            options: {
              campaign: this.model,
              canSetCurrency: this.canSetCurrency,
              hasStarted: this.hasStarted,
              showCurrentFlights: this.showCurrentFlights === "true"
            }
          }]
        }
      });
    },
    ensureDefaultCurrencyCode: function () {
      const {
        model: model,
        orgModel: orgModel
      } = this;
      if (orgModel !== undefined) {
        if (this.isNewCampaign || !model.get("currency_code")) {
          model.set({
            currency_code: orgModel.get("currency_code")
          });
        }
      }
    },
    formatImpressionAmount: function (e) {
      var $target = $(e.currentTarget);
      var impAmount = $target.val().replace(/,/g, "");
      $target.val($.trim(formatsRolledUpNumbersNoAbbreviation(impAmount)));
    },
    setGenderSelections: function (demographics = [], countryCode, isActive) {
      let isFemaleSelected = false;
      let isMaleSelected = false;
      demographics.forEach(demographic => {
        const femaleIndicator = 1e3;
        const maleIndicator = 2e3;
        if (demographic >= maleIndicator) {
          isMaleSelected = true;
        } else if (demographic >= femaleIndicator) {
          isFemaleSelected = true;
        }
      });
      this.model.set({
        nielsen_gender_female: isFemaleSelected,
        nielsen_gender_male: isMaleSelected
      });
      this.nielsenDemographicsModel.set({
        nielsen_enabled: isActive
      });
      this.nielsenDemographicsModel.set({
        providerData: {
          demographics: demographics
        }
      });
      this.nielsenDemographicsModel.set({
        nielsen_primary_country: countryCode
      });
    },
    currencySelectChangeHandler: function (e) {
      var currCode = $(e.currentTarget).val();
      this.doCurrencySelectChange(currCode);
    },
    doCurrencySelectChange: function (currencyCode) {
      var currCode = currencyCode;
      var jsonObj = {
        currency_symbol: getCurrencySymbol(currCode)
      };
      const $budgetInput = this.$(".budget-set-currency");
      const $dailySpendCapInput = this.$(".spend-manual");
      const $monetaryCapInput = this.$(".spend-manual");
      var t1Masks = T1.Form.Masks;
      const $input = this.$(".goal-value .input input");
      selectCurrCode = currCode;
      this.renderPartial(".currency-budget-col .symbol", jsonObj);
      this.renderPartial(".vendor-agreements .symbol", jsonObj);
      this.renderPartial(".spend-cap .symbol", jsonObj);
      this.updateCurrencySymbolForVendorAgreements(currCode);
      this.$(".goal-value .symbol")[0].innerHTML = jsonObj.currency_symbol;
      this.$(".goal-value .symbol")[1].innerHTML = jsonObj.currency_symbol;
      this.currencyCode = currCode;
      if (!goalTypePCT.includes(this.goalType)) {
        if ($input.val().length) {
          $input.attr("alt", "currency");
        }
      }
      this.renderPartial(".monetary-manual .symbol", jsonObj);
      t1Masks.attach($("input", $monetaryCapInput), this.currencyCode);
      t1Masks.attach($("input", $budgetInput), this.currencyCode);
      t1Masks.attach($("input", $dailySpendCapInput), this.currencyCode);
    },
    load: function () {
      let $goalType, $impressionAuto, $impressionCapNote, $impressionManual, $impressionPacingSelect, $monetaryAuto, $monetaryCapNote, $monetaryManual, $monetaryPacingSelect;
      var self = this;
      const {
        model: model,
        el: $el
      } = this;
      const $input = this.$(".goal-value .input input");
      this.hasStarted = CampaignStrategyUtils.hasEntityStarted(model);
      this.isNewCampaign = model.id === undefined;
      this.selectCreated = false;
      if (this.loadedSubViews) {
        this.loadedSubViews = [];
      }
      if (useAllocationPacing) {
        this.spend_cap_type = "t1-optimized";
        this.impression_cap_type = "no-limit";
        this.useT1MonetarySpend = true;
        this.useT1ImpressionSpend = false;
      }
      this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(this.model);
      this.id = model.id || null;
      if (this.id) {
        this.useCampaignFreqCap = useCampaignFreqCapTester(model.toJSON());
        this.useCampaignDailySpend = useCampaignDailySpendCapTester(model.toJSON());
        this.goalType = model.get("goal_type");
      } else {
        this.useCampaignFreqCap = false;
      }
      this.isMinimizeSetOnAdvertiser = model.get("advertiser") && model.get("advertiser").get("minimize_multi_ads") === "1";
      this.prepareDatePicker();
      if (this.budgetFlightFlag) {
        this.render().then(function () {
          T1.Selectbox(self.$(".budget-set-currency .select"), {
            enableDirectionAuto: false,
            searchFieldDisabled: false,
            searchInputPlaceHolder: "Search currencies",
            onChange: function (e) {
              self.currencySelectChangeHandler(e);
            }
          });
          const $dateDisplay = self.$(".date-display");
          const $monetaryCap = self.$(".monetary-cap");
          const $impressionCap = self.$(".impression-cap");
          const $impressionSpendCapOpts = self.$(".impression-spend-cap-opts");
          const $impressionCapTypeValue = model.get("impression_cap_type");
          const $monetarySpendCapOpts = self.$(".monetary-spend-cap-opts");
          const $monetaryCapTypeValue = model.get("spend_cap_type");
          const $monetaryCapAutomatic = model.get("spend_cap_automatic");
          const $impressionCapAutomatic = model.get("impression_cap_automatic");
          const $monetaryCapTypeOpts = self.$(".monetary-cap .spend-type");
          const $impresionCapTypeOpts = self.$(".impression-cap .spend-type");
          $monetaryCapNote = self.$(".monetary-note");
          $monetaryManual = $monetaryCap.find(".monetary-manual");
          $monetaryAuto = $monetaryCap.find(".monetary-auto");
          $impressionCapNote = self.$(".impression-note");
          $impressionManual = $impressionCap.find(".impression-manual");
          $impressionAuto = $impressionCap.find(".impression-auto");
          $monetaryPacingSelect = self.$(".monetary-pacing .select");
          $impressionPacingSelect = self.$(".impression-pacing .select");
          if (useAllocationPacing) {
            if (!self.isNewCampaign) {
              $impressionSpendCapOpts.find("[checked]").removeAttr("checked");
              $monetarySpendCapOpts.find("[checked]").removeAttr("checked");
              if ($impressionCapTypeValue === "t1-optimized") {
                self.useT1ImpressionSpend = false;
                $impressionSpendCapOpts.find("[value=0]").attr("checked", "checked");
              } else if ($impressionCapTypeValue === "asap" || $impressionCapTypeValue === "even" || $impressionCapTypeValue === "no-limit") {
                self.useT1ImpressionSpend = true;
                $impressionSpendCapOpts.find("[value=1]").attr("checked", "checked");
                $impressionPacingSelect.val($impressionCapTypeValue).trigger("liszt:updated");
                if ($impressionCapTypeValue === "no-limit") {
                  $impresionCapTypeOpts.css("visibility", "hidden");
                } else {
                  $impresionCapTypeOpts.css("visibility", "visible");
                  if ($impressionCapAutomatic === "1") {
                    $impresionCapTypeOpts.val("auto").trigger("liszt:updated");
                  } else {
                    $impresionCapTypeOpts.val("manual").trigger("liszt:updated");
                  }
                }
              }
              if ($monetaryCapTypeValue === "t1-optimized") {
                self.useT1MonetarySpend = true;
                $monetarySpendCapOpts.find("[value=1]").attr("checked", "checked");
              } else if ($monetaryCapTypeValue === "asap" || $monetaryCapTypeValue === "even" || $monetaryCapTypeValue === "no-limit") {
                self.useT1MonetarySpend = false;
                $monetarySpendCapOpts.find("[value=0]").attr("checked", "checked");
                $monetaryPacingSelect.val($monetaryCapTypeValue).trigger("liszt:updated");
                if ($monetaryCapTypeValue === "no-limit") {
                  $monetaryCapTypeOpts.css("visibility", "hidden");
                  $monetaryManual.css("visibility", "hidden");
                  $monetaryAuto.css("visibility", "hidden");
                } else {
                  $monetaryCapTypeOpts.css("visibility", "visible");
                  if ($monetaryCapAutomatic === "1") {
                    $monetaryCapTypeOpts.val("auto").trigger("liszt:updated");
                    $monetaryManual.css("visibility", "hidden");
                    $monetaryAuto.css("visibility", "visible");
                  } else {
                    $monetaryCapTypeOpts.val("manual").trigger("liszt:updated");
                    $monetaryAuto.css("visibility", "hidden");
                    $monetaryManual.css("visibility", "visible");
                  }
                }
              }
            }
          }
          if (goalTypePCT.includes(self.goalType)) {
            $input.data("precision", twoDigits);
            $input.attr({
              alt: "currencyPercent",
              min: 0,
              max: 100
            });
          }
          T1.Tooltip($monetaryCap, {
            offset: 5,
            delayIn: "100",
            delayOut: "100"
          });
          T1.Tooltip($impressionCap, {
            offset: 5,
            delayIn: "100",
            delayOut: "100"
          });
          const $minimazeAd = self.$(".minimize-ad");
          if (self.isNewCampaign) {
            self.advertiserSelect.load();
          }
          if (self.adServerModel) {
            self.adServerModel.id = model ? model.get("ad_server_id") : null;
          }
          self.renderSpendCapMode(self.isNewCampaign);
          self.adServerSelect.load();
          self.vendorAgreements.load();
          T1.Truncate($el, {
            el: ".basic-info .title",
            tooltip: true
          });
          T1.Tooltip($dateDisplay, {
            offset: 5,
            delayIn: "100",
            delayOut: "100"
          });
          T1.Tooltip($minimazeAd, {
            offset: 5,
            delayIn: "100",
            delayOut: "100"
          });
          T1Timezones.done(function (mthds) {
            self.timezones = mthds;
            if (!self.model.id) {
              self.zoneName = self.preferences.get("zoneName") || self.timezones.getDefaultZoneName();
            }
            self.renderPartial(".date-display");
            if (self.budgetFlightsLayout) {
              const budgetFlightsOptions = self.budgetFlightsLayout.layout[".budget-flight-box"][0].options;
              budgetFlightsOptions.zoneName = self.zoneName;
              budgetFlightsOptions.timeZones = self.timezones;
              self.budgetFlightsLayout.load();
            }
            T1.DoubleDatePicker({
              getStartDate: () => self.startDate,
              getEndDate: () => self.endDate,
              chooseTime: true,
              chooseTimezone: true,
              timezoneEditable: !self.hasStarted,
              startTimeEditable: () => !self.hasStarted,
              lowerBounds: self.hasStarted ? Date.parse(self.startDate) : new Date(),
              startDisabled: () => self.hasStarted,
              zoneName: () => self.zoneName || "",
              positionBy: "bottomTop",
              topOffset: 25,
              closeOnScroll: false,
              onSave: function ({
                data: data,
                error: error,
                success: success
              }) {
                const saveValues = data.saveValues;
                var newStartDate = Date.parse(saveValues.start.date);
                self.timezones.validateStartDate(self.startBufferMin, newStartDate, saveValues.zoneName, {
                  onSuccess: function () {
                    const successValues = data.saveValues;
                    var successStartDate = Date.parse(successValues.start.date);
                    var successEndDate = Date.parse(successValues.end.date);
                    if (Date.compare(successEndDate, successStartDate) !== 1) {
                      error({
                        message: self.startEndOrderError
                      });
                      return;
                    }
                    self.startDate = successStartDate;
                    self.endDate = successEndDate;
                    self.startTime = successStartDate.toString(self.displayTimeFormat);
                    self.endTime = successEndDate.toString(self.displayTimeFormat);
                    self.zoneName = successValues.zoneName;
                    self.preferences.save();
                    self.renderPartial(".date-display");
                    self.removeTooltip({
                      currentTarget: $dateDisplay
                    });
                    success();
                  },
                  onError: function () {
                    error({
                      message: self.startDateError
                    });
                  },
                  hasStarted: self.hasStarted
                });
              }
            }, $el);
          });
          self.pixelSelect = new T1Layout({
            el: () => $el,
            selectorPath: ".pixel-select",
            template: '<div class="pixel-select-box"></div>',
            layout: {
              ".pixel-select-box": [{
                module: "pixel",
                viewType: "select",
                options: {
                  getAdvertiserId: () => self.$('[data-bind="advertiser_id"]').attr("value"),
                  id: model.get("merit_pixel_id")
                }
              }]
            }
          });
          if (self.hasStarted) {
            self.onGoalTypeChange({
              target: {
                value: model.get("goal_type")
              }
            });
          } else {
            self.selectCreated = false;
            T1.Selectbox($goalType, {
              onChange: function (e) {
                self.onGoalTypeChange(e);
              }
            });
          }
          const changedWaitTime = 50;
          setTimeout(function () {
            $goalType.trigger("change");
            self.isFormElementsChanged = false;
            self.checkMaxPCPV();
          }, changedWaitTime);
          const createdWaitTime = 150;
          setTimeout(function () {
            self.selectCreated = true;
          }, createdWaitTime);
          self.activateInputMasks();
          if (window.hybridWorkflowParams.isHybrid) {
            updateCancelBtn();
            self.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
            self.$cancelBtn = $(".cancelNewT1");
          }
        });
        const $monetarySelect = this.$(".monetary-cap .select");
        const $impressionSelect = this.$(".impression-cap .select");
        const $monetarySelectHold = this.$(".monetary-cap .form-select");
        const $impressionSelectHold = this.$(".impression-cap .form-select");
        const $capHeader = this.$(".monetary-cap .spend-cap-header");
        $goalType = this.$(".goal-type .select");
        $monetaryPacingSelect = this.$(".monetary-pacing .select");
        $impressionPacingSelect = this.$(".impression-pacing .select");
        T1.Selectbox($monetaryPacingSelect, {
          onChange: function (e) {
            if (e.currentTarget.value === "no-limit") {
              $monetarySelectHold.css("visibility", "hidden");
              $monetaryCapNote.css("visibility", "hidden");
              if ($impressionPacingSelect.val() === "no-limit") {
                $capHeader.hide();
              } else {
                $capHeader.show();
              }
            } else {
              $monetarySelectHold.css("visibility", "visible");
              $monetaryCapNote.css("visibility", "visible");
              $capHeader.show();
            }
          }
        });
        T1.Selectbox($impressionPacingSelect, {
          onChange: function (e) {
            if (e.currentTarget.value === "no-limit") {
              $impressionSelectHold.css("visibility", "hidden");
              $impressionCapNote.css("visibility", "hidden");
              if ($monetaryPacingSelect.val() === "no-limit") {
                $capHeader.hide();
              } else {
                $capHeader.show();
              }
            } else {
              $impressionSelectHold.css("visibility", "visible");
              $impressionCapNote.css("visibility", "visible");
              $capHeader.show();
            }
          }
        });
        T1.Selectbox($monetarySelect, {
          onChange: function (e) {
            if (e.currentTarget.value === "manual") {
              $monetaryAuto.hide();
              $monetaryManual.css("visibility", "visible");
              $monetaryManual.show();
            } else if (e.currentTarget.value === "auto") {
              $monetaryManual.hide();
              $monetaryAuto.show();
            }
          }
        });
        T1.Selectbox($impressionSelect, {
          onChange: function (e) {
            if (e.currentTarget.value === "manual") {
              $impressionAuto.hide();
              $impressionManual.show();
            } else if (e.currentTarget.value === "auto") {
              $impressionManual.hide();
              $impressionAuto.show();
            }
          }
        });
        T1.Selectbox($(".frequency .select", $el));
        T1.Selectbox($(".ad-server .select", $el));
        this.isUnloaded = false;
        this.hideAdvancedSection();
        this.setPacingOnPG(this.isProgrammaticGuaranteed);
        this.showPGNotificatonComponent();
        this.setBidOnCrossDevice(this.isProgrammaticGuaranteed);
        this.toggleBidOnMinDevices(this.isProgrammaticGuaranteed);
      }
    },
    getBudgetFlightsView: function () {
      if (!this.budgetFlightView) {
        this.budgetFlightView = this.budgetFlightsLayout.layout[".budget-flight-box"][0].view;
      }
      return this.budgetFlightView;
    },
    disableRoiTargetingInputs: function () {
      const $goalType = this.$(".goal");
      var $goalTypeSelect = $goalType.find(".goal-type .select");
      var $goalTypeValueInput = $goalType.find(".goal-value .text");
      var $symbol = $goalType.find(".goal-value .symbol");
      var $aov = $goalType.find(".aov");
      const $attribution = this.$(".attribution");
      var $customAttributionActive = $attribution.find(".roi-activate");
      var $customAttributionInactive = $attribution.find(".roi-inactive");
      $goalTypeSelect.removeAttr("disabled", "disabled").trigger("liszt:updated");
      $goalTypeValueInput.removeAttr("disabled", "disabled");
      $symbol.removeClass("disabled");
      $aov.removeClass("disabled");
      if (this.hasStarted) {
        $customAttributionActive.attr("disabled", "disabled");
        $customAttributionInactive.attr("disabled", "disabled");
      } else {
        $customAttributionActive.removeAttr("disabled", "disabled");
        $customAttributionInactive.removeAttr("disabled", "disabled");
      }
    },
    renderSpendCapMode: function (isNewCampaign) {
      var model = this.model;
      const $monetaryCap = this.$(".monetary-cap");
      const $monetaryManual = $monetaryCap.find(".monetary-manual");
      const $monetaryAuto = $monetaryCap.find(".monetary-auto");
      const $impressionCap = this.$(".impression-cap");
      const $impressionManual = $impressionCap.find(".impression-manual");
      const $impressionAuto = $impressionCap.find(".impression-auto");
      if (isNewCampaign || model.get("spend_cap_automatic") === "1") {
        $monetaryManual.hide();
        $monetaryAuto.show();
      } else {
        $monetaryManual.show();
        $monetaryAuto.hide();
      }
      if (isNewCampaign || model.get("impression_cap_automatic") === "1") {
        $impressionManual.hide();
        $impressionAuto.show();
      } else {
        $impressionManual.show();
        $impressionAuto.hide();
      }
    },
    updateCurrencySymbolForVendorAgreements: function (currencyCode) {
      var vendorAgreementView = this.vendorAgreements;
      if (vendorAgreementView && vendorAgreementView.layout) {
        let targetView = vendorAgreementView.layout[".vendor-wrapper"];
        targetView = targetView && targetView[0] && targetView[0].view ? targetView[0].view : null;
        if (targetView && targetView.updateCurrencySymbol) {
          targetView.updateCurrencySymbol(currencyCode);
        }
      }
    },
    events: {
      "click .status": "onStatusClick",
      "click .pc-window-chk": "meritPixelOptions",
      "click .pv-window-chk": "meritPixelOptions",
      "click .advanced-settings-outer-toggle": "showAdvancedSection",
      "click .advanced-settings-toggle": "hideAdvancedSection",
      "changed .form-select.merit": "updateConversionVariableSelect",
      "changed .form-input.one-merit-event-per strand-input": "updateConversionVariableValue",
      "changed .form-select.minutes strand-dropdown": "updateConversionVariableRangeSelect",
      "change .post-view-attr input": "checkPvaPct",
      "click  .managed-by .radio": "managedByRadioHandler",
      "click .spend-cap-chk": "toggleDailySpendCapOptions",
      "change .goal-type .select": "showGoalTypeNotification",
      "click .show-currentandfuture-only": "onShowCurrentAndFutureClick",
      "click .stl-org-default-chk": "onStlOrgDefaultClick",
      "click .stl-flag-level-chk": "onStlFlagLevelClick",
      "changed .stl-dropdown": "onStlDropDownChange",
      "click .TPAS-enabled-chk": "onTpaRadioClick",
      "click .restrict-same-device-targeting": "onRestrictSameDeviceRadioClick",
      "change .pc_pv": "checkMaxPCPV",
      "changed .frequency-cap": "toggleFrequencyCapOptions",
      "selected .monetary-spend-cap-opts": "toggleMonetaryCapOptions",
      "selected .impression-spend-cap-opts": "toggleImpressionCapOptions",
      "change .frequency-type .select": "toggleFrequencyCapOptions",
      "change .impression-manual input": "formatImpressionAmount",
      "changed .post-bid-viewability-checkbox": "onViewabilityChecked",
      "selected .form-select.tag-type .select": "onTagTypeSelected",
      "selected .form-select.measurement-type .select": "onViewabilityDropdownSelected",
      "selected .form-select.vendor .select": "onVendorSelected",
      "change .monetary-cap.alloc-pacing .select": "onMonetaryCapAmtSelected",
      "change .impression-cap.alloc-pacing .select": "onImpressionCapAmtSelected",
      "change .monetary-pacing.alloc-pacing .select": "onMonetaryCapTypeSelected",
      "change .impression-pacing.alloc-pacing .select": "onImpressionCapTypeSelected",
      "click .campaign-pg-chk": "onProgrammaticCheckboxClick",
      "focus .col-amount input": "onBudgetFlightsInputChange",
      "focus .col-imp-amount input": "onBudgetFlightsInputChange",
      "click .audience-verification-checkbox": "onAudienceVerificationCheckboxClick",
      "click .nielsen-gender-checkbox": "onNielsenGenderCheckboxClick",
      "click .ndar strand-pulldown-button .all-checkbox": "onNDARAllCheckboxClick",
      "click .ndar strand-pulldown-button .item-checkbox": "onNDARItemCheckboxClick",
      "open .ndar strand-pulldown-button": "updateAllItemCheckbox",
      "changed #ndarCountryDDL": "onNdarCountryCodeChange",
      "click .bid-min-devices": "onBidMinDeviceCheckBoxClick",
      "click .dba-group .radio": "onDynamicBudgetAllocationBoxClick"
    },
    suspiciousTrafficValue: "",
    suspiciousTrafficValueChanged: false,
    suspiciousTrafficDescription: {
      STL_NONE: "(no traffic is filtered - NOT RECOMMENDED)",
      STL_LIGHT: "(filters confirmed fraudulent traffic and mildly suspicious traffic)",
      STL_MODERATE: "(filters confirmed fraudulent traffic and moderately suspicious traffic)",
      STL_STRONG: "(filters confirmed fraudulent traffic and highly suspicious traffic)",
      STL_MAXIMUM: "(filters confirmed fraudulent traffic and all suspicious traffic)"
    },
    suspiciousTrafficShortDescription: {
      STL_STANDARD: "(T1 Standard)",
      STL_NONE: "(T1 Standard Disabled)",
      STL_LIGHT: "(T1 Standard + Light Increase)",
      STL_MODERATE: "(T1 Standard + Moderate increase)",
      STL_STRONG: "(T1 Standard + Strong increase)",
      STL_MAXIMUM: "(T1 Standard + Maximum increase)"
    },
    managedByRadioHandler: function (e) {
      const $msgContainer = this.$(".campaign-general-form .info-box-holder-managed .info-box");
      var managed = false;
      if (e.currentTarget.value === "MANAGED") {
        managed = true;
        $msgContainer.slideDown(defaultAnimationSpeed);
      } else {
        managed = false;
        $msgContainer.slideUp(defaultAnimationSpeed);
      }
      T1.EventHub.publish("campaign:updateMenuItems", {
        isManaged: managed
      });
    },
    clearValidationConversionErrors: function ($dl, $hiddenInput, isVariable) {
      var dl = $dl[0];
      var strandInput = dl.querySelector("strand-input");
      if (strandInput.error !== true || strandInput.value === "" && isVariable === false) {
        this.removeTooltipOnElement($dl);
        dl.removeAttribute("title");
        dl.removeAttribute("original-title");
        this.removeTooltipOnElement($(strandInput));
        Polymer.dom(strandInput).removeAttribute("original-title");
        $hiddenInput[0].classList.remove("error");
      }
    },
    updateConversionVariableSelect: function (e) {
      var model = this.model;
      var select = e.target;
      var $parent = $(select).closest("dl");
      var $optionalInputs = $parent.find(".can-disable");
      var input = $optionalInputs.filter("strand-input")[0];
      model.set({
        conversion_type: select.value
      });
      if (select.value === "variable") {
        $optionalInputs.removeAttr("disabled");
        const minutes = model.get("last_conversion_variable_minutes") || model.get("conversion_variable_minutes") || "1";
        const range = convertMinutesToRange(minutes, 0);
        input.value = range.rangeValue;
      } else {
        input.value = "";
        input.error = false;
        $optionalInputs.attr({
          disabled: "disabled"
        });
      }
    },
    updateConversionVariableMinutes: function (element, key) {
      let isSafeForStoringLastValue;
      var model = this.model;
      var $parent = $(element).closest("dl");
      var $hiddenInput = $parent.find('input[type="hidden"]');
      var modelUpdate = {};
      var isVariable = model.get("conversion_type") === "variable";
      modelUpdate[key] = element.value;
      model.set(modelUpdate);
      this.clearValidationConversionErrors($parent, $hiddenInput, isVariable);
      let minutes = model.get("conversion_variable_input") || "";
      if (/^\d*$/.test(minutes)) {
        isSafeForStoringLastValue = true;
        minutes = T1.Utils.getMinutes(model.get("conversion_variable_input"), model.get("conversion_variable_minutes_select")) || "";
      }
      if (isSafeForStoringLastValue && isVariable) {
        model.set({
          last_conversion_variable_minutes: minutes
        });
      }
      model.set({
        conversion_variable_minutes: minutes
      });
      $hiddenInput.val(minutes);
    },
    updateConversionVariableValue: function (e) {
      this.updateConversionVariableMinutes(e.target, "conversion_variable_input");
    },
    updateConversionVariableRangeSelect: function (e) {
      this.updateConversionVariableMinutes(e.target, "conversion_variable_minutes_select");
    },
    onShowCurrentAndFutureClick: function () {
      this.showCurrentFlights = Boolean(this.el.find(".show-currentandfuture-only").is(":checked"));
    },
    onStatusClick: function (e) {
      this.status = parseInt($(e.currentTarget).attr("value"), 10);
      this.showPGNotificatonComponent($(e.target).closest("label").find("input").val());
    },
    isNdarChecked: function () {
      const audienceVerificationChkb = this.$(".audience-verification-checkbox");
      return audienceVerificationChkb[0].disabled;
    },
    showPGNotificatonComponent: function (radioVal) {
      const pgNotificationComp = this.$(".pg-inline-box");
      const radioBtnVal = radioVal === "0" || this.$(".status").find("input").eq(1).attr("checked");
      if (this.isProgrammaticGuaranteed && radioBtnVal) {
        pgNotificationComp.show();
      } else {
        pgNotificationComp.hide();
      }
    },
    checkPvaPct: function (event) {
      const $target = $(event.currentTarget);
      const value = $target.val();
      if (value !== "") {
        const intVal = parseInt(value, 10);
        const maxValue = 100;
        if (intVal > maxValue) {
          $target.val(maxValue);
        }
      }
    },
    showGoalTypeNotification: function (e) {
      var goalType = e.target.value;
      const $curContainer = this.$(".goal-value .cur");
      const $pctContainer = this.$(".goal-value .pct");
      const $input = this.$(".goal-value .input input");
      const $openContainer = this.$(".campaign-general-form .info-box-holder-in .info-box");
      const $myMsgContainer = this.$(`.campaign-general-form .${goalType}-info-box-holder .info-box`);
      var t1Masks = T1.Form.Masks;
      const $goalValueInput = this.$(".goal-value");
      var maskFormatObj = {};
      const twoPointPrecision = 2;
      const fourPointPrecision = 4;
      maskFormatObj.isMaskPercent = goalTypePCT.includes(prevGoalType);
      maskFormatObj.isMaskCurrency = !goalTypePCT.includes(prevGoalType);
      if (this.selectCreated) {
        $openContainer.slideUp(defaultAnimationSpeed).addClass(".info-box-holder-in");
        if (goalType === "roi" || goalType === "vcr" || goalType === "viewability_rate" || goalType === "vcpm") {
          $myMsgContainer.slideDown(defaultAnimationSpeed).removeClass(".info-box-holder-in");
        }
        if (goalTypePCT.includes(goalType)) {
          if (goalTypePCT.includes(prevGoalType)) {
            return;
          }
          $input.data("precision", twoPointPrecision);
          $input.attr({
            alt: "currencyPercent",
            min: 0,
            max: 100
          });
          $curContainer.addClass("hidden");
          $pctContainer.removeClass("hidden");
        } else {
          $input.removeAttr("min");
          $input.removeAttr("max");
          $input.data("precision", fourPointPrecision);
          $input.attr("alt", "currency");
          $curContainer.removeClass("hidden");
          $pctContainer.addClass("hidden");
        }
      } else {
        if (goalTypePCT.includes(goalType)) {
          if (goalTypePCT.includes(prevGoalType)) {
            return;
          }
          $input.data("precision", twoPointPrecision);
          $input.attr({
            alt: "currencyPercent",
            min: 0,
            max: 100
          });
        } else {
          $input.removeAttr("min");
          $input.removeAttr("max");
          $input.data("precision", fourPointPrecision);
          $input.attr("alt", "currency");
          $curContainer.removeClass("hidden");
          $pctContainer.addClass("hidden");
        }
      }
      t1Masks.attach($("input", $goalValueInput), selectCurrCode, maskFormatObj);
    },
    updateAdvancedSectionPanel: function (goalType) {
      var target = $.find(".campaign-general-form .advanced-section");
      var $attributionAdvanced = $(target[0]).find(".attribution-advanced");
      if (goalType && (goalType === "cpe" || goalType === "cpa" || goalType === "roi")) {
        $attributionAdvanced.removeClass("attribution-hide");
      } else {
        $attributionAdvanced.addClass("attribution-hide");
      }
    },
    onGoalTypeChange: function (args) {
      let loadedSubViews = this.loadedSubViews;
      var self = this;
      const $el = this.el;
      var value = args.target.value;
      const $attribution = this.$(".attribution");
      var partialViewSettings = {
        attribution: {
          templateTarget: ".attribution",
          $target: $attribution,
          onHide: function () {
            self.pixelSelect.unload.call(self);
          },
          onLoad: function () {
            T1.Selectbox($(".select", $attribution));
            self.pixelSelect.load();
          }
        }
      };
      const $aov = this.$(".aov");
      prevGoalType = this.model.get("goal_type");
      this.goalType = value;
      if (value === "viewability_rate" || value === "vcpm") {
        this.$(".post-bid-viewability-checkbox")[0].checked = true;
      } else {
        this.toggleViewabilityError(false);
      }
      function hidePartialView(target) {
        var currentTarget = partialViewSettings[target];
        const canBeRemoved = loadedSubViews.includes(target);
        const multi = {
          days: 1440,
          hours: 60
        };
        if (!canBeRemoved) {
          return;
        }
        const $target = currentTarget.$target;
        const $pcMinutes = $target.find('[data-bind="pc_window_minutes"]');
        const $pvMinutes = $target.find('[data-bind="pv_window_minutes"]');
        const $pcUnit = $target.find('[data-bind="pcWindowUnit"]');
        const $pvUnit = $target.find('[data-bind="pvWindowUnit"]');
        const pcUnit = $pcUnit.length === 1 ? $pcUnit[0].value : "";
        const pvUnit = $pvUnit.length === 1 ? $pvUnit[0].value : "";
        self.lastPcMinutes = $pcMinutes.length === 1 ? $pcMinutes[0].value * multi[pcUnit] : "";
        self.lastPvMinutes = $pvMinutes.length === 1 ? $pvMinutes[0].value * multi[pvUnit] : "";
        $target.slideUp(defaultAnimationSpeed);
        currentTarget.onHide();
        $target.empty();
        loadedSubViews.splice(loadedSubViews.indexOf(target), 1);
        if (!loadedSubViews.length) {
          $target.parent(".additional-info").animate({
            marginBottom: "0"
          }, animSpeed);
        }
      }
      function loadPartialView(target) {
        var currentTarget = partialViewSettings[target];
        var $target = currentTarget.$target;
        var renderObj = self.serialize();
        const isAlreadyLoaded = loadedSubViews.includes(target);
        if (isAlreadyLoaded) {
          return;
        }
        loadedSubViews.push(target);
        $target.hide();
        renderObj[`${target}Visible`] = true;
        self.renderPartial(currentTarget.templateTarget, renderObj);
        currentTarget.onLoad();
        $target.slideDown(defaultAnimationSpeed).parent(".additional-info").animate({
          marginBottom: "30"
        }, animSpeed);
        self.checkMaxPCPV();
      }
      function populateAttribution() {
        loadPartialView("attribution");
        const select = $el.find(".merit-select")[0];
        if (select) {
          self.updateConversionVariableSelect({
            target: select
          });
        }
      }
      this.updateAdvancedSectionPanel(value);
      if (!this.loadedSubViews) {
        this.loadedSubViews = [];
      }
      loadedSubViews = this.loadedSubViews;
      if (value && (value === "cpe" || value === "cpa" || value === "roi")) {
        if (this.dontSavePromise) {
          this.dontSavePromise.then(populateAttribution);
        } else {
          populateAttribution();
        }
      } else {
        hidePartialView("attribution");
      }
      this.dontSavePromise = null;
      $attribution.find(".roi-section").slideDown();
      if (value === "roi") {
        $aov.show();
      } else {
        $aov.hide();
      }
      const $roiTooltip = $el.find(".tooltip-holder");
      T1.Tooltip($roiTooltip, {
        offset: 5,
        delayIn: "100",
        delayOut: "100"
      });
      if (this.selectCreated === true) {
        T1.EventHub.publish("campaign:goal-type-change", {
          goalType: value
        });
      }
      this.model.set({
        goal_type: value
      });
      this.toggleFrequencyCapOptions();
      this.disableRoiTargetingInputs(value);
    },
    resetPCPVInput: function (whichInput) {
      if (whichInput === "pc" || whichInput === "pv") {
        const input = this.el.find(`input[data-bind="${whichInput}_window_minutes"]`);
        input.val("");
        input.removeClass("error");
        input.parent().removeAttr("original-title");
        input.parent().removeAttr("title");
      }
    },
    meritPixelOptions: function (e) {
      const pcWindowChk = this.el.find(".pc-window-chk");
      const pvWindowChk = this.el.find(".pv-window-chk");
      this.isFormElementsChanged = true;
      if (!pcWindowChk.is(":checked")) {
        this.resetPCPVInput("pc");
      }
      if (!pvWindowChk.is(":checked")) {
        this.resetPCPVInput("pv");
      }
      if (!pcWindowChk.is(":checked") && !pvWindowChk.is(":checked")) {
        $($(e.currentTarget).data("enable-chk")).attr("checked", "checked");
      }
      this.checkMaxPCPV();
    },
    toggleMonetaryCapOptions: function (evt) {
      var monetarySpendCapValue = evt.currentTarget.getAttribute("value");
      const monetaryPacingType = this.$(".monetary-pacing .spend-type");
      const monetaryPacingSelect = this.$(".monetary-pacing .select");
      const monetaryCapType = this.$(".monetary-cap .spend-type");
      const monetaryNote = this.$(".monetary-cap .monetary-note");
      if (this.isProgrammaticGuaranteed) {
        monetaryPacingSelect.val("no-limit").trigger("liszt:updated");
        monetaryPacingType.css("visibility", "visible");
      } else if (monetarySpendCapValue === "1") {
        monetaryPacingType.css("visibility", "hidden");
        monetaryCapType.css("visibility", "hidden");
        monetaryNote.css("visibility", "hidden");
        this.useT1MonetarySpend = true;
      } else {
        monetaryPacingSelect.val("asap").trigger("liszt:updated");
        monetaryPacingType.css("visibility", "visible");
        monetaryCapType.css("visibility", "visible");
        monetaryNote.css("visibility", "visible");
        this.useT1MonetarySpend = false;
      }
    },
    toggleImpressionCapOptions: function (evt) {
      var impressionSpendCapValue = evt.currentTarget.getAttribute("value");
      const impressionPacingType = this.$(".impression-pacing .spend-type");
      const impressionCapType = this.$(".impression-cap .spend-type");
      const impressionNote = this.$(".impression-cap.impression-note");
      const impressionPacingSelect = this.$(".impression-pacing .select");
      const impressionSpendCap = this.$(".impression-spend-cap-opts");
      if (impressionSpendCapValue === "0") {
        impressionPacingType.css("visibility", "hidden");
        impressionCapType.css("visibility", "hidden");
        impressionNote.css("visibility", "hidden");
        this.useT1ImpressionSpend = true;
      } else {
        impressionPacingSelect.val("no-limit").trigger("liszt:updated");
        impressionPacingType.css("visibility", "visible");
        this.useT1ImpressionSpend = false;
      }
      this.removeValidationErrors(impressionSpendCap);
    },
    removeValidationErrors: function (formEle) {
      formEle.find(".error").each(function (i, errorElem) {
        $(errorElem).removeClass("error");
      });
      formEle.find("[original-title]").each(function (j, attrElem) {
        $(attrElem).removeAttr("original-title error title");
      });
      $(formEle).removeAttr("original-title");
    },
    onMonetaryCapAmtSelected: function (e) {
      const monetaryCapAmountVal = e.target.value;
      const $monetaryCapNote = this.$(".monetary-cap .monetary-note");
      if (monetaryCapAmountVal === "") {
        $monetaryCapNote.css("visibility", "hidden");
      } else {
        $monetaryCapNote.css("visibility", "visible");
      }
    },
    onImpressionCapAmtSelected: function (e) {
      const impCapAmountVal = e.target.value;
      const $impressionCapNote = this.$(".impression-cap.impression-note");
      if (impCapAmountVal === "") {
        $impressionCapNote.css("visibility", "hidden");
      } else {
        $impressionCapNote.css("visibility", "visible");
      }
    },
    onMonetaryCapTypeSelected: function (e) {
      const monetaryCapTypeVal = e.target.value;
      const $monetaryCapAmount = this.$(".monetary-cap.alloc-pacing .spend-type");
      const $monetaryCapNote = this.$(".monetary-cap .monetary-note");
      const monetaryCapAmountVal = $monetaryCapAmount.find(".select")[0].value;
      if (monetaryCapTypeVal === "" || monetaryCapTypeVal === "no-limit") {
        $monetaryCapAmount.css("visibility", "hidden");
        $monetaryCapNote.css("visibility", "hidden");
      } else {
        if (monetaryCapAmountVal === "") {
          $monetaryCapNote.css("visibility", "hidden");
        } else {
          $monetaryCapNote.css("visibility", "visible");
        }
      }
    },
    onImpressionCapTypeSelected: function (e) {
      const impCapTypeVal = e.target.value;
      const $impressionCapAmount = this.$(".impression-cap.alloc-pacing .spend-type");
      const $impressionCapNote = this.$(".impression-cap.impression-note");
      const impressionCapAmountVal = $impressionCapAmount.find(".select")[0].value;
      if (impCapTypeVal === "" || impCapTypeVal === "no-limit") {
        $impressionCapAmount.css("visibility", "hidden");
        $impressionCapNote.css("visibility", "hidden");
      } else {
        if (impressionCapAmountVal === "") {
          $impressionCapNote.css("visibility", "hidden");
        } else {
          $impressionCapNote.css("visibility", "visible");
          this.removeValidationErrors($impressionCapAmount);
        }
      }
    },
    setMonetaryPacingOnPG: function (isChecked) {
      const $monetaryPacingSelect = this.$(".monetary-pacing .select");
      const $monetarySpendCapOpts = this.$(".monetary-spend-cap-opts");
      const $monetaryCapTypeOpts = this.$(".monetary-cap .spend-type");
      const $monetaryCap = this.$(".monetary-cap");
      const $monetaryManual = $monetaryCap.find(".monetary-manual");
      const $monetaryAuto = $monetaryCap.find(".monetary-auto");
      if (isChecked) {
        this.useT1MonetarySpend = false;
        requestAnimationFrame(() => {
          $monetarySpendCapOpts.attr("disabled", "disabled").find("[value=0]").attr("checked", "checked");
        });
        $monetaryPacingSelect.val("no-limit").attr("disabled", "disabled").trigger("liszt:updated");
        $monetaryCapTypeOpts.css("visibility", "hidden");
        $monetaryManual.css("visibility", "hidden");
        $monetaryAuto.css("visibility", "hidden");
      } else {
        $monetaryPacingSelect.removeAttr("disabled").trigger("liszt:updated");
        $monetarySpendCapOpts.removeAttr("disabled");
      }
    },
    setImpressionPacingOnPG: function (isChecked) {
      const $impressionPacingSelect = this.$(".impression-pacing .select");
      const $impressionSpendCapOpts = this.$(".impression-spend-cap-opts");
      const $impresionCapTypeOpts = this.$(".impression-cap .spend-type");
      const $impressionCap = this.$(".impression-cap");
      if (isChecked) {
        this.useT1ImpressionSpend = true;
        $impressionSpendCapOpts.attr("disabled", "disabled").find("[value=1]").attr("checked", "checked");
        $impressionPacingSelect.val("no-limit").attr("disabled", "disabled").trigger("liszt:updated");
        $impresionCapTypeOpts.css("visibility", "hidden");
        $impressionCap.css("visibility", "hidden");
      } else {
        this.useT1ImpressionSpend = false;
        $impressionPacingSelect.removeAttr("disabled").trigger("liszt:updated");
        $impressionSpendCapOpts.removeAttr("disabled");
      }
      if (useAllocationPacing) {
        this.useT1ImpressionSpend = false;
      }
    },
    setPacingOnPG: function (isChecked) {
      this.setMonetaryPacingOnPG(isChecked);
      this.setImpressionPacingOnPG(isChecked);
      if (!useAllocationPacing) {
        if (isChecked) {
          const $capHeader = this.$(".monetary-cap .spend-cap-header");
          $capHeader.hide();
        }
      }
    },
    toggleBidOnMinDevices: function (isProgrammaticGuaranteedFlag) {
      const $bidMinDevicesSect = this.$(".same-device-feature");
      if (isProgrammaticGuaranteedFlag) {
        $bidMinDevicesSect.hide();
      } else {
        $bidMinDevicesSect.show();
      }
    },
    onProgrammaticCheckboxClick: function (e) {
      const isChecked = e.target.checked;
      this.isProgrammaticGuaranteed = isChecked;
      this.model.set({
        is_programmatic_guaranteed: isChecked ? "1" : "0"
      });
      this.setPacingOnPG(isChecked);
      this.toggleFrequencyCapOptions();
      this.setAdvancedState(this.isProgrammaticGuaranteed);
      this.toggleBidOnMinDevices(this.isProgrammaticGuaranteed);
      this.showPGNotificatonComponent();
    },
    onBudgetFlightsInputChange: function () {
      if (this.model.get("is_programmatic_guaranteed") === "1" && this.showPGAlert) {
        this.showPGAlert = false;
        const alertMsg = new T1Alert();
        alertMsg.initialize({
          title: "Caution",
          bodyMsg: `Monetary & Impression budget caps are for\n                      directional purposes only and do not cut off spend\n                      in T1.\n                      Please ensure that the publisher sets budget and\n                      impression caps in their ad server.`,
          buttons: [{
            text: "Confirm",
            class: "save",
            click: function () {
              const $dialog = $(this);
              $dialog.dialog("close");
            }
          }]
        });
        alertMsg.show();
      }
    },
    onBidMinDeviceCheckBoxClick: function (e) {
      this.model.set({
        bid_min_devices: e.target.checked ? "2" : "1"
      });
    },
    onDynamicBudgetAllocationBoxClick: function () {
      if (this.$(".dba-group").val() === "OFF") {
        this.$(".dba-inline-message").hide();
      } else {
        this.$(".dba-inline-message").show();
      }
    },
    onAudienceVerificationCheckboxClick: function (e) {
      this.nielsenDemographicsModel.set({
        nielsen_enabled: e.target.checked
      });
      this.renderPartial(".column-Aud-Verif-B");
    },
    onNielsenGenderCheckboxClick: function ({
      target: target
    }) {
      this.model.set({
        [target.getAttribute("data-bind")]: target.checked
      });
      this.renderPartial(".column-Aud-Verif-B");
    },
    onNDARItemCheckboxClick: function ({
      target: target
    }) {
      const $pulldown = this.$(target).closest("strand-pulldown-button");
      const providerData = Object.assign({
        demographics: []
      }, this.nielsenDemographicsModel.get("providerData"));
      const checkedValue = parseInt(target.getAttribute("data-bind").split("nielsen_age_")[1], 10);
      providerData.demographics = this.getUpdatedDemographics(providerData.demographics, checkedValue, target.checked);
      this.nielsenDemographicsModel.set({
        providerData: providerData
      });
      this.updateAllItemCheckbox({
        target: $pulldown[0]
      });
      this.updateItemLabel($pulldown);
      this.removeTooltipOnElement($pulldown);
    },
    onNDARAllCheckboxClick: function ({
      target: target
    }) {
      const isChecked = target.checked;
      const $pulldown = this.$(target).closest("strand-pulldown-button");
      const $allItems = $pulldown.find(".item-checkbox");
      const providerData = Object.assign({
        demographics: []
      }, this.nielsenDemographicsModel.get("providerData"));
      $allItems.prop("checked", isChecked).each(item => {
        const itemTarget = $allItems[item];
        const checkedValue = parseInt(itemTarget.getAttribute("data-bind").split("nielsen_age_")[1], 10);
        providerData.demographics = this.getUpdatedDemographics(providerData.demographics, checkedValue, itemTarget.checked);
      });
      this.nielsenDemographicsModel.set({
        providerData: providerData
      });
      this.updateItemLabel($pulldown);
      this.removeTooltipOnElement($pulldown);
    },
    getUpdatedDemographics: function (currentDemographics, checkedValue, isChecked) {
      if (isChecked) {
        currentDemographics.push(checkedValue);
      } else {
        currentDemographics = currentDemographics.filter(demo => checkedValue !== demo);
      }
      return currentDemographics;
    },
    updateAllItemCheckbox: function ({
      target: target
    }) {
      const $pulldown = this.$(target);
      const $all = $pulldown.find(".all-checkbox");
      const selectedItems = $pulldown.find(".item-checkbox[checked]").length;
      const allItems = $pulldown.find(".item-checkbox").length;
      $all.prop("state", this.getAllCheckboxState(selectedItems, allItems));
    },
    onNdarCountryCodeChange: function (event) {
      if (this.isNewCampaign) {
        this.nielsenDemographicsModel.set({
          nielsen_primary_country: event.target.value
        });
      }
    },
    getAllCheckboxState: function (selected, all) {
      let state = "unchecked";
      if (selected === all) {
        state = "checked";
      } else if (selected > 0) {
        state = "partial";
      }
      return state;
    },
    updateItemLabel: function ($pulldown) {
      const $allItems = $pulldown.find(".item-checkbox");
      const $selectedItems = $allItems.filter("[checked]");
      const typeText = $pulldown.hasClass("nielsen-gender") ? "Gender" : "Age Range";
      $pulldown.find(".selected-label").text(this.getSelectedItemLabel($allItems, $selectedItems, typeText));
    },
    getSelectedItemLabel: function (allItems, selectedItems, typeText = "Age Range") {
      return selectedItems.length === 0 ? `Select ${typeText}` : selectedItems.length === 1 ? `${typeText}: ${selectedItems[0].text || selectedItems[0].textContent}` : selectedItems.length === allItems.length ? `${typeText}: All` : `${typeText}: ${selectedItems.length} selected`;
    },
    toggleFrequencyCapOptions: function () {
      let frequencyToggle;
      var self = this;
      const frequency = this.$(".frequency");
      var frequencySelect = frequency.find(".frequency-type .select, .frequency-interval .select");
      var freqAmount = frequency.find(".frequency-amount");
      var freqType = frequency.find(".frequency-type .select");
      var freqIntv = frequency.find(".frequency-interval .select");
      var freqAmountInput = freqAmount.find(".text");
      var freqAmountLabel = freqAmount.find(".frequency-amount-label");
      const frequencyOpts = frequency.find(".frequency-cap").length ? frequency.find(".frequency-cap") : false;
      var noLimitWarning = frequency.find(".no-limit-warning");
      const $minimizeAd = this.$(".minimize-ad .checkbox");
      var frequencyOptsToggle = false;
      var reachOrSpend = this.goalType === "reach" || this.goalType === "spend";
      const advertiser = this.model.get("advertiser");
      if (this.isProgrammaticGuaranteed) {
        if (frequencyOpts) {
          frequencyOpts.attr("disabled", "disabled").find("[value=on]").prop("checked", true);
        }
        frequencySelect.val("no-limit").attr("disabled", "disabled").trigger("liszt:updated");
        freqAmountInput.attr("value", "").css("visibility", "hidden");
        freqAmountLabel.css("visibility", "hidden");
        freqIntv.parent().css("visibility", "hidden").trigger("liszt:updated");
        $minimizeAd.attr("disabled", "disabled");
        $minimizeAd.attr("checked", true);
        noLimitWarning.hide();
      } else {
        if (frequencyOpts) {
          frequencyOptsToggle = true;
          if (reachOrSpend) {
            frequencyOpts[0].value = "on";
            frequencyOpts.attr("disabled", "disabled");
          } else {
            frequencyOpts.removeAttr("disabled");
          }
        }
        frequencyToggle = frequencyOptsToggle && frequencyOpts[0].value === "on";
        frequency.find(".error").each(function (i, errorElem) {
          self.removeTooltip({
            currentTarget: errorElem
          });
        });
        frequency.find("[original-title]").each(function (j, attrElem) {
          $(attrElem).removeAttr("original-title error title");
        });
        if (frequencyToggle) {
          this.useCampaignFreqCap = true;
          frequencySelect.removeAttr("disabled").trigger("liszt:updated");
          freqAmountInput.removeAttr("disabled");
          freqAmountLabel.removeClass("disabled");
        } else {
          this.useCampaignFreqCap = false;
          frequencySelect.val(0);
          freqAmountInput.attr("value", "");
          frequencySelect.attr("disabled", "disabled").trigger("liszt:updated");
          freqAmountInput.attr("disabled", "disabled");
          freqAmountLabel.addClass("disabled");
        }
        if (frequencyOpts) {
          if (frequencyOptsToggle) {
            frequencyOpts.css("visibility", "visible");
            if (freqType.val() === "no-limit") {
              noLimitWarning.show();
              freqIntv.parent().css("visibility", "hidden").trigger("liszt:updated");
              freqAmountInput.css("visibility", "hidden");
              freqAmountLabel.css("visibility", "hidden");
            } else {
              noLimitWarning.hide();
              freqIntv.parent().css("visibility", "visible").trigger("liszt:updated");
              freqAmountInput.css("visibility", "visible");
              freqAmountLabel.css("visibility", "visible");
            }
          } else {
            frequencyOpts.find("[checked]").removeAttr("checked");
            frequencyOpts.find("[value=off]").attr("checked", "checked");
            frequencyOpts.attr("disabled", "disabled");
            frequencySelect.val(0);
            freqAmountInput.attr("value", "");
          }
        }
        if (advertiser && advertiser.get("minimize_multi_ads") === "1") {
          $minimizeAd.attr("disabled", "disabled");
        }
      }
    },
    toggleDailySpendCapOptions: function () {
      const spendCap = this.$(".spend-cap");
      var spendCapType = spendCap.find(".select");
      var spendLb = spendCap.find(".spend-label");
      var spendManualInput = spendCap.find(".text");
      var currencySym = spendCap.find(".symbol");
      this.isFormElementsChanged = true;
      if (spendCap.find(".spend-cap-chk").is(":checked")) {
        this.useCampaignDailySpend = true;
        spendCapType.removeAttr("disabled").trigger("liszt:updated");
        spendManualInput.removeAttr("disabled");
        spendLb.removeClass("disabled");
        currencySym.removeClass("disabled");
      } else {
        this.useCampaignDailySpend = false;
        spendCapType.attr("disabled", "disabled").trigger("liszt:updated");
        spendManualInput.attr("disabled", "disabled");
        spendLb.addClass("disabled");
        currencySym.addClass("disabled");
      }
    },
    showAdvancedSection: function () {
      const $collapsedSection = this.$(".campaign-general-form .advanced-settings-outer");
      const $expandedSection = this.$(".campaign-general-form .advanced-section");
      this.setAdvancedState(this.isProgrammaticGuaranteed);
      $expandedSection.show();
      $collapsedSection.hide();
    },
    hideAdvancedSection: function () {
      const $collapsedSection = this.$(".campaign-general-form .advanced-settings-outer");
      const $expandedSection = this.$(".campaign-general-form .advanced-section");
      $expandedSection.hide();
      $collapsedSection.show();
    },
    setBidOnCrossDevice: function (isProgrammaticGuaranteed) {
      const $sameDeviceRadioGroup = this.$(".same-device-radio-group");
      const $bidMinDevicesOp = this.$(".bid-min-devices");
      if (isProgrammaticGuaranteed) {
        this.model.set({
          connected_id_type: "",
          bid_min_devices: "1"
        });
        $sameDeviceRadioGroup.attr("disabled", "disabled");
        $bidMinDevicesOp.attr("disabled", "disabled");
      } else {
        $sameDeviceRadioGroup.removeAttr("disabled");
        $bidMinDevicesOp.removeAttr("disabled");
      }
      this.renderPartial(".same-device-message");
      this.renderPartial(".bid-min-devices-op");
    },
    setAdvancedState: function (isProgrammaticGuaranteed) {
      const $tpaRadioGroup = this.$(".tpa-radio-group");
      const $tpaRadioEnabledButton = this.$(".tpa-radio-enabled");
      const $sameDeviceRadioEnabledButton = this.$(".same-device-radio-enabled");
      const $stlRadioGroup = this.$(".stl-radio-group");
      const $stlDropdown = this.$(".stl-dropdown");
      const $stlMessage = this.$(".stl-message");
      const $stlInfo1 = this.$(".stl-info");
      const $stlInfo2 = this.$(".stl-info2");
      const $stlOrgDefaultChk = this.$(".stl-org-default-chk");
      const $stlFlagLevelChk = this.$(".stl-flag-level-chk");
      const $adsTxtDropdown = this.$(".ads-text-dropdown");
      if (isProgrammaticGuaranteed) {
        $tpaRadioGroup.attr("disabled", "disabled");
        $tpaRadioEnabledButton.attr("checked", "checked");
        $sameDeviceRadioEnabledButton.attr("checked", "checked");
        $stlRadioGroup.attr("disabled", "disabled");
        $stlFlagLevelChk.attr("checked", "checked");
        $stlDropdown.attr("disabled", "disabled");
        $stlDropdown[0].value = "STL_NONE";
        if ($adsTxtDropdown.length > 0) {
          $adsTxtDropdown.attr("disabled", "disabled");
          $adsTxtDropdown[0].value = "NONE";
        }
        $stlMessage.text("");
        $stlInfo1.addClass("stl-info-hide");
        $stlInfo2.addClass("stl-info-hide2");
      } else {
        $tpaRadioGroup.removeAttr("disabled");
        $stlRadioGroup.removeAttr("disabled");
        if (this.isNewCampaign && $adsTxtDropdown.length > 0) {
          $adsTxtDropdown.removeAttr("disabled");
          $adsTxtDropdown[0].value = "INHERITED";
        }
        if (this.suspicious_traffic_level_default === true) {
          $stlOrgDefaultChk.attr("checked", "checked");
          $stlDropdown[0].placeholder = "Select One";
          $stlDropdown[0].value = "";
          $stlDropdown[0].initialValue = false;
          $stlMessage.text("");
        } else {
          $stlFlagLevelChk.attr("checked", "checked");
          $stlDropdown.removeAttr("disabled");
        }
      }
      this.setBidOnCrossDevice(isProgrammaticGuaranteed);
    },
    setMinimizeCheckbox: function ({
      advertiser: advertiser
    }) {
      const $minimizeSection = this.$(".minimize-ad");
      var $checkbox = $minimizeSection.find(".checkbox");
      var $advLb = $minimizeSection.find(".advertiser-ad-collision");
      const $msgContainer = this.$(".campaign-general-form .minimizeAdCollision-info-box-holder .info-box");
      if (advertiser && advertiser.minimize_multi_ads === "1") {
        $checkbox.prop("disabled", true);
        $checkbox.prop("checked", true);
        $advLb.text("(This setting has been activated at the Advertiser level)");
        $msgContainer.slideUp(defaultAnimationSpeed);
      }
    },
    preparePostData: function (formValues) {
      var model = this.model;
      var zoneName = model.get("zone_name") || "";
      var modelStart = `${model.get("start_date")} ${zoneName}`;
      var modelEnd = `${model.get("end_date")} ${zoneName}`;
      var initialVals, objKeys;
      var tpaRadioValue = $(".tpa-radio-group")[0];
      var sameDeviceRadioValue = $(".same-device-radio-group")[0];
      if (this.useDynamicBudgetAllocation) {
        formValues.dynamic_budget_allocation_type = $(".dba-group")[0].value;
      }
      if (this.isNewCampaign) {
        initialVals = this.getBudgetFlightsView().getInitialValues();
        formValues.start_date = `${initialVals.start_date} ${this.zoneName}`;
        formValues.end_date = `${initialVals.end_date} ${this.zoneName}`;
        formValues.total_budget = initialVals.total_budget;
        formValues.total_impression_budget = initialVals.total_impression_budget;
      } else {
        delete formValues.start_date;
        delete formValues.end_date;
        delete formValues.total_budget;
        delete formValues.total_impression_budget;
      }
      objKeys = _.keys(formValues);
      _.each(objKeys, function (key) {
        if (key.includes("budgetflight_total_budget_") || key.includes("total_impression_budget_") || key.includes("budgetflight_general")) {
          delete formValues[key];
        }
      });
      if (modelStart === formValues.start_date) {
        delete formValues.start_date;
      }
      if (modelEnd === formValues.end_date) {
        delete formValues.end_date;
      }
      formValues.zone_name = this.zoneName;
      formValues.frequency_optimization = this.useCampaignFreqCap ? 0 : 1;
      if (!this.useCampaignFreqCap) {
        delete formValues.frequency_type;
        delete formValues.frequency_amount;
        delete formValues.frequency_interval;
      }
      if (formValues.frequency_type === "no-limit") {
        formValues.frequency_amount = "";
        formValues.frequency_interval = "not-applicable";
      }
      if (formValues.spend_cap_automatic !== "") {
        formValues.spend_cap_automatic = formValues.spend_cap_automatic === "auto" ? "1" : "0";
      }
      if (formValues.impression_cap_automatic !== "") {
        formValues.impression_cap_automatic = formValues.impression_cap_automatic === "auto" ? "1" : "0";
      }
      formValues.spend_cap_enabled = this.useCampaignDailySpend ? 1 : 0;
      if (formValues.impression_cap_type === "no-limit" || formValues.impression_cap_automatic === "1") {
        delete formValues.impression_cap_amount;
      }
      if (formValues.minimize_multi_ads || formValues.minimize_multi_ads === "1") {
        formValues.minimize_multi_ads = "1";
      } else {
        formValues.minimize_multi_ads = "0";
      }
      formValues.political = formValues.political ? "1" : "0";
      if (this.isMinimizeSetOnAdvertiser) {
        formValues.minimize_multi_ads = "0";
      }
      if (formValues.impression_cap_amount) {
        formValues.impression_cap_amount = formValues.impression_cap_amount.replace(/,/g, "");
      }
      if (useAllocationPacing === true) {
        if (this.useT1MonetarySpend === true) {
          formValues.spend_cap_type = "t1-optimized";
          formValues.spend_cap_automatic = "1";
          delete formValues.spend_cap_amount;
        }
        if (this.useT1ImpressionSpend === true) {
          formValues.impression_cap_type = "t1-optimized";
          formValues.impression_cap_automatic = "1";
          delete formValues.impression_cap_amount;
        }
      }
      if (formValues.spend_cap_type === "no-limit" || formValues.spend_cap_automatic === "1") {
        formValues.spend_cap_automatic = "1";
        delete formValues.spend_cap_amount;
      }
      delete formValues["spend-cap"];
      if (formValues.goal_type === undefined) {
        formValues.goal_type = model.get("goal_type");
      }
      if (formValues.conv_attribution) {
        const attributionValue = formValues.conv_attribution;
        if (attributionValue === "t1") {
          formValues.has_custom_attribution = "0";
          delete formValues.custom_attribution_source;
        } else {
          formValues.has_custom_attribution = "1";
          formValues.custom_attribution_source = attributionValue === "3rd-party" ? "3rd Party Attribution" : "T1 MTA via Neustar";
        }
      }
      delete formValues.conv_attribution;
      if (formValues.restrict_targeting_org_enabled) {
        formValues.restrict_targeting_to_deterministic_id = tpaRadioValue.value;
      }
      delete formValues.restrict_targeting_org_enabled;
      delete formValues.suspicious_traffic_level;
      if (sameDeviceRadioValue.value === "enabled" || sameDeviceRadioValue.value === "deterministic") {
        formValues.restrict_targeting_to_same_device_id = "0";
        formValues.connected_id_type = "DETERMINISTIC_ONLY";
      } else if (sameDeviceRadioValue.value === "hybrid") {
        formValues.restrict_targeting_to_same_device_id = "0";
        formValues.connected_id_type = "DETERMINISTIC_FIRST";
      } else if (sameDeviceRadioValue.value === "disabled") {
        formValues.restrict_targeting_to_same_device_id = "1";
        formValues.connected_id_type = "";
      }
      formValues.bid_min_devices = model.get("bid_min_devices") || "1";
      switch (this.suspiciousTrafficValue) {
        case "STL_STANDARD":
          formValues.override_suspicious_traffic_filter = "0";
          formValues.suspicious_traffic_filter_level = "25";
          break;
        case "STL_NONE":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "0";
          break;
        case "STL_LIGHT":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "25";
          break;
        case "STL_MODERATE":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "50";
          break;
        case "STL_STRONG":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "75";
          break;
        case "STL_MAXIMUM":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "100";
          break;
      }
      if (this.model.get("override_suspicious_traffic_filter") === "1" && this.suspiciousTrafficValue === "STL_STANDARD") {
        formValues.override_suspicious_traffic_filter = "1";
        formValues.suspicious_traffic_filter_level = "-1";
      }
      formValues.use_viewability_reporting = this.model.get("use_viewability_reporting") || false;
      if (!moatAutoTagPermitted && formValues.viewability_vendor_id === moatVendorID) {
        formValues.viewability_type = "BYOV";
      } else {
        formValues.viewability_type = formValues.viewability_type || "";
      }
      if (formValues.is_programmatic_guaranteed) {
        delete formValues.impression_cap_automatic;
        delete formValues.spend_cap_automatic;
        delete formValues.minimize_multi_ads;
        delete formValues.override_suspicious_traffic_filter;
        delete formValues.frequency_optimization;
        formValues.is_programmatic_guaranteed = "1";
        formValues.connected_id_type = "";
        formValues.restrict_targeting_to_same_device_id = "0";
        formValues.dynamic_budget_allocation_type = "OFF";
      } else {
        delete formValues.is_programmatic_guaranteed;
      }
      if (typeof formValues.accountable_and_addressable !== "undefined") {
        formValues.accountable_and_addressable = formValues.accountable_and_addressable ? "1" : "0";
      }
      return formValues;
    },
    showSuspiciousTrafficErrors: function (args) {
      const suspiciousTrafficDropDown = this.$(".stl-dropdown");
      const errorConfig = {
        getExternalTip: false,
        className: "w-FormError",
        offset: 10,
        trigger: "hover"
      };
      _.each(args, function (errorVal) {
        if (errorVal.field === "suspicious_traffic_filter_level") {
          errorConfig.tooltipEle = suspiciousTrafficDropDown;
          suspiciousTrafficDropDown.attr("title", "This is a required field.");
          T1.Tooltip(suspiciousTrafficDropDown, errorConfig);
          suspiciousTrafficDropDown.addClass("error");
        }
      });
    },
    setViewabilityErrorMessage: function (message) {
      var element = this.el.find(".viewability-inline-error-message")[0];
      if (element) {
        Polymer.dom(element).textContent = message;
      }
    },
    toggleViewabilityError: function (toShow) {
      var stateFunctionName = toShow ? "show" : "hide";
      this.el.find(".viewability-inline-error-message")[stateFunctionName]();
    },
    postData: function (formValues, successCallback, statusInvalid, conflictCallback) {
      var self = this;
      var model = this.model;
      var vendorContracts = model.get("vendor_contract");
      var generalErrMsg = "There are errors on the page.";
      const ndarFormValues = Object.entries(formValues).reduce(getNDARFormValues, {});
      if (this.getBudgetFlightsView().validateBudgetFlightData(this.isNewCampaign) !== true) {
        statusInvalid([], generalErrMsg);
        return;
      }
      model.set({
        emptyImpBudget: this.getBudgetFlightsView().emptyImpBudgetCheck(this.isNewCampaign)
      });
      if (!model.get("emptyImpBudget") && !formValues.total_impression_budget) {
        formValues.total_impression_budget = model.get("total_impression_budget");
      }
      delete formValues.spend_cap_enabled;
      const confirmation = this.isNewCampaign ? "Created" : "Saved";
      if (formValues.goal_value) {
        goalValue = parseFloat(String(formValues.goal_value).replace(/,/g, "")).toFixed(twoDigits);
      }
      if (formValues.ads_txt_verified === "INHERITED") {
        formValues.ads_txt_verified = "";
        model.set({
          ads_txt_verified: "INHERITED"
        });
      }
      if (formValues.goal_type !== "roi" && formValues.goal_type !== "cpa" && model.get("has_custom_attribution") === "1") {
        formValues.has_custom_attribution = "0";
        delete formValues.custom_attribution_source;
      }
      function executeSuccessHandler() {
        if (!T1Permissions.check("campaign.edit", model)) {
          T1.Location.set(`#campaign/readonly/${model.id}/details`);
          T1.Notify("message", `Campaign ${confirmation}`);
          successCallback(model, {
            doNotNavigateAway: true
          });
        } else {
          successCallback();
          self.$(".attribution .info-box").slideUp(animSpeed);
        }
      }
      function onOverallSuccessHandler() {
        let arrErrs, errorMsg;
        self.isFormElementsChanged = false;
        self.vendorAgreements.load();
        self.isNewCampaign = false;
        T1.EventHub.publish("campaign:goal-type-change", {
          goalType: self.model.get("goal_type"),
          isSave: true
        });
        if (!self.model.budgetFlightError) {
          self.getBudgetFlightsView().saveBudgetFlights(model.id, self.isNewCampaign).done(function () {
            self.refreshModelData({
              allowReload: false
            }).then(function () {
              const agencyId = self.model.attributes.advertiser.attributes.agency_id;
              LaunchDarklyUtils.getPromise().then(() => {
                LaunchDarklyUtils.updateAgency(agencyId).then(() => {
                  const budgetFlightView = self.getBudgetFlightsView();
                  if (T1Permissions.check("feature", "mediaocean_prisma_integration")) {
                    self.model.set({
                      showCPEIO: true
                    });
                  }
                  budgetFlightView.load();
                  budgetFlightView.isFormElementsChanged = false;
                  executeSuccessHandler();
                });
              });
            });
          }).fail(function (args) {
            if (args) {
              if (args.isConflict === true) {
                conflictCallback();
              } else if (args.data && (args.data.error || args.data.errors)) {
                errorMsg = args.data.errors && args.data.errors.field_error && args.data.errors.field_error.error ? args.data.errors.field_error.error : args.data.error;
                self.model.budgetFlightError = args.data;
                statusInvalid([{
                  field: "budgetflight_general",
                  message: errorMsg
                }], errorMsg, undefined, true);
              } else {
                arrErrs = args.errObj ? [{
                  field: "budgetflight_general",
                  message: args.errObj.errMsg
                }] : [];
                statusInvalid(arrErrs, generalErrMsg, undefined, true);
              }
            }
          });
        } else {
          const budgetFlightError = self.model.budgetFlightError;
          errorMsg = budgetFlightError.errors.field_error.error;
          arrErrs = budgetFlightError && budgetFlightError ? [{
            field: "budgetflight_general",
            message: errorMsg
          }] : [];
          statusInvalid(arrErrs, errorMsg, undefined, true);
        }
      }
      function onStatusInvalid(errorsArr) {
        var indexOfConversionError = CampaignStrategyUtils.indexOfError(errorsArr, "conversion_variable_minutes");
        var indexOfViewabilityError = CampaignStrategyUtils.indexOfError(errorsArr, "use_viewability_reporting");
        const indexOfViewabilityVendorError = CampaignStrategyUtils.indexOfError(errorsArr, "viewability_vendor_id");
        self.showSuspiciousTrafficErrors(errorsArr);
        if (indexOfViewabilityError >= 0) {
          self.setViewabilityErrorMessage(errorsArr[indexOfViewabilityError].message);
          self.toggleViewabilityError(true);
        } else if (indexOfViewabilityVendorError >= 0) {
          self.setViewabilityErrorMessage("Viewability vendor is a required field");
          self.toggleViewabilityError(true);
        } else {
          self.toggleViewabilityError(false);
        }
        if (indexOfConversionError >= 0) {
          errorsArr.push({
            field: "conversion_variable_input",
            message: errorsArr[indexOfConversionError].message
          });
        }
        if (errorsArr[0].field === "zone_name") {
          statusInvalid([{
            field: "budgetflight_general",
            message: errorsArr[0].message
          }], errorsArr[0].message, undefined, true);
        } else {
          statusInvalid(errorsArr);
        }
      }
      function createEditCampaign() {
        model.createEdit(formValues, {
          success: function () {
            var serialize = self.serialize();
            self.toggleViewabilityError(false);
            self.originalRestrictTpas = model.get("restrict_targeting_to_deterministic_id");
            self.originalSameDevice = model.get("restrict_targeting_to_same_device_id");
            self.originalSuspiciousOverride = model.get("override_suspicious_traffic_filter");
            self.originalSuspiciousLevel = model.get("suspicious_traffic_filter_level");
            self.originalGoalType = model.get("goal_type");
            self.originalViewabilityReportingPermitted = model.get("use_viewability_reporting");
            self.originalVendorId = model.get("viewability_vendor_id");
            self.originalViewabilityType = model.get("viewability_type");
            self.originalViewabilityRate = model.get("viewability_sample_rate");
            originalVendorContracts = model.get("vendor_contract");
            serialize.goal_value = goalValue;
            if (window.hybridWorkflowParams.isHybrid) {
              updateBreadCrumbNewT1(false, model.toJSON());
            } else {
              updateBreadCrumb(false, model.toJSON());
            }
            delete serialize.status;
            if (serialize.conversion_type !== "variable") {
              serialize.conversion_variable_minutes = "";
            }
            delete serialize.conversion_type;
            serialize.pvWindowUnit = serialize.pv_window_minutes_select;
            serialize.pcWindowUnit = serialize.pc_window_minutes_select;
            if (!serialize.pv_window_minutes) {
              serialize.pv_window_minutes = "";
            }
            self.updateDataBind(serialize, self.el);
            self.renderManyPartials([".form-list.advertiser", ".form-list.ndar"], serialize);
            self.setMinimizeCheckbox(model.toJSON());
            if (self.isNewCampaign) {
              constructPromises();
            } else {
              onOverallSuccessHandler();
            }
            self.renderSpendCapMode(false);
            self.el.find(".spend-type").removeAttr("original-title");
            self.el.find(".impression-spend-type-t1-optimized").removeAttr("original-title");
          },
          statusInvalid: onStatusInvalid,
          conflict: conflictCallback
        });
      }
      function constructPromises() {
        const promises = [saveVendorContracts()];
        const nielsenDemographicsModel = self.nielsenDemographicsModel;
        if (useNdarPermitted) {
          promises.push(nielsenDemographicsModel.createEdit(model, ndarFormValues, statusInvalid).then(() => {
            if (!nielsenDemographicsModel.get("nielsen_enabled")) {
              nielsenDemographicsModel.clear();
            }
          }));
        }
        utilsAsync.linkPromises(promises).then(function () {
          if (self.isNewCampaign) {
            onOverallSuccessHandler();
          } else {
            createEditCampaign();
          }
        }).catch(() => {});
      }
      function saveVendorContracts() {
        var showVendorsErrors = function (errorsList) {
          var $vendorsList = self.el.find(".w-vendorsList");
          $.each(errorsList, function (index, vendorErrors) {
            var $agreement = $vendorsList.find(`[data-id=${vendorErrors.id}]`);
            self.showErrors(undefined, vendorErrors.errors, $agreement);
          });
          statusInvalid([]);
        };
        return utilsAsync.makePromise((resolve, reject) => {
          vendorContracts.save(model.id, resolve, errorsList => {
            showVendorsErrors(errorsList);
            reject();
          }, conflictCallback);
        });
      }
      if (this.isNewCampaign) {
        createEditCampaign();
      } else {
        constructPromises();
      }
    },
    onBudgetFlightChange: function () {
      this.model.budgetFlightError = undefined;
    },
    onDontSave: function () {
      this.refreshModelData({
        allowReload: false
      }, true).then(() => {
        const budgetFlightColl = this.model.get("budget_flight");
        if (budgetFlightColl) {
          budgetFlightColl.refreshCollection();
        }
        const budgetFlView = this.getBudgetFlightsView();
        if (budgetFlView.onDontSave !== undefined) {
          $.proxy(budgetFlView.onDontSave, budgetFlView)();
        }
      });
      this.getBudgetFlightsView().isFormElementsChanged = false;
      T1.EventHub.publish("campaign:goal-type-change", {
        goalType: this.model.get("goal_type"),
        isDontSave: true
      });
    },
    refreshModelData: function (options, dontSave) {
      var model = this.model;
      var self = this;
      var allowReload = options && options.allowReload !== undefined ? options.allowReload : true;
      var allowUpdateBreadcrumbs = options && options.allowUpdateBreadcrumbs !== undefined ? options.allowUpdateBreadcrumbs : false;
      var deferred = $.Deferred();
      this.currencyCode = null;
      if (model.id) {
        model.unset("pc_window_minutes", {
          silent: true
        });
        model.unset("pv_window_minutes", {
          silent: true
        });
        this.dontSavePromise = deferred;
        if (dontSave) {
          model.set({
            restrict_targeting_to_deterministic_id: this.originalRestrictTpas
          }, {
            silent: true
          });
          model.set({
            restrict_targeting_to_same_device_id: this.originalSameDevice
          }, {
            silent: true
          });
          model.set({
            override_suspicious_traffic_filter: this.originalSuspiciousOverride
          }, {
            silent: true
          });
          model.set({
            suspicious_traffic_filter_level: this.originalSuspiciousLevel
          }, {
            silent: true
          });
          model.set({
            goal_type: this.originalGoalType
          }, {
            silent: true
          });
          model.set({
            use_viewability_reporting: this.originalViewabilityReportingPermitted
          }, {
            silent: true
          });
          model.set({
            viewability_vendor_id: this.originalVendorId
          }, {
            silent: true
          });
          model.set({
            viewability_type: this.originalViewabilityType
          }, {
            silent: true
          });
          model.set({
            viewability_sample_rate: this.originalViewabilityRate
          }, {
            silent: true
          });
          model.set({
            vendor_contract: originalVendorContracts
          });
          const unsavedModels = model.get("vendor_contract").models.filter(obj => !obj.get("campaign_id"));
          Object.keys(unsavedModels).forEach(key => {
            model.get("vendor_contract").remove(unsavedModels[key]);
          });
        } else {
          model.get("vendor_contract").reset([], {
            silent: true
          });
          model.unset("restrict_targeting_to_deterministic_id", {
            silent: true
          });
          model.unset("restrict_targeting_to_same_device_id", {
            silent: true
          });
          model.unset("override_suspicious_traffic_filter", {
            silent: true
          });
          model.unset("suspicious_traffic_filter_level", {
            silent: true
          });
          model.unset("goal_type", {
            silent: true
          });
          model.unset("viewability_vendor_id", {
            silent: true
          });
          model.unset("viewability_type", {
            silent: true
          });
          model.unset("viewability_sample_rate", {
            silent: true
          });
        }
        model.fetch({
          params: {
            with: ["vendor_contracts,vendor"],
            full: "*"
          },
          success: function () {
            var vendorAgreements = model.get("vendor_contract");
            if (!dontSave) {
              if (vendorAgreements && vendorAgreements.models && vendorAgreements.models.length) {
                $.each(vendorAgreements.models, function (index, vendorModel) {
                  vendorModel.unset("action", {
                    silent: true
                  });
                });
              }
            }
            if (model.get("is_programmatic_guaranteed")) {
              self.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(model);
              self.disableRPGCheckbox();
            }
            if (allowReload) {
              self.load();
            }
            if (allowUpdateBreadcrumbs) {
              if (window.hybridWorkflowParams.isHybrid) {
                updateBreadCrumbNewT1(false, self.model.toJSON());
              } else {
                updateBreadCrumb(false, self.model.toJSON());
              }
            }
            deferred.resolve();
          },
          skipOrgSynchronization: true
        });
      }
      return deferred.promise();
    },
    disableRPGCheckbox: function () {
      this.$(".campaign-pg-chk").attr("disabled", true);
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var campaignName = "untitled campaign";
      var budgetFlightsChanged = false;
      const vendorAgreementChanged = this.vendorAgreements ? !this.vendorAgreements.canUnload(onTabChange) : false;
      isFormElementsChanged = isFormElementsChanged || vendorAgreementChanged;
      const budgetFlightsView = this.getBudgetFlightsView();
      budgetFlightsChanged = budgetFlightsView ? budgetFlightsView.hasBudgetFlightsChanged() : false;
      isFormElementsChanged = isFormElementsChanged || budgetFlightsChanged;
      if (isFormElementsChanged) {
        const model = this.model;
        if (model.get("name")) {
          campaignName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: campaignName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: campaignName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    cancelUnload: function () {
      T1.EventHub.publish("campaign-create-edit:cancelUnload");
    },
    onVersionConflictClose: function () {
      this.refreshModelData({
        allowUpdateBreadcrumbs: true
      });
    },
    getSuspiciousTrafficLevel: function (overrideFlag, level) {
      var key = "STL_STANDARD";
      if (overrideFlag === "0" && level === "25") {
        key = "STL_STANDARD";
      } else if (overrideFlag === "1") {
        key = overrideLevels[level] || key;
      }
      return key;
    },
    checkMaxPixel: function (pixel) {
      var isTooHigh = false;
      const dayInHours = 24;
      const maxValue = 30;
      if (this.$(`.${pixel}-window-chk`).is(":checked")) {
        let inputValue = parseFloat(this.$(`[data-bind="${pixel}_window_minutes"]`).val());
        const selectValue = this.$(`[data-bind="${pixel}WindowUnit"]`).val();
        if (selectValue === "hours") {
          inputValue /= dayInHours;
        }
        if (inputValue > maxValue) {
          isTooHigh = true;
        }
      }
      return isTooHigh;
    },
    checkMaxPCPV: function () {
      var isTooHigh = ["pc", "pv"].some(this.checkMaxPixel, this);
      if (isTooHigh) {
        this.$(".pc-pv-too-high > strand-inline-box").removeAttr("hidden").end().find(".merit-pixels-list").addClass("extra-padding");
      } else {
        this.$(".pc-pv-too-high > strand-inline-box").attr("hidden", "hidden").end().find(".merit-pixels-list").removeClass("extra-padding");
      }
    },
    onStlDropDownChange: function (evt) {
      var $target = $(evt.target).closest(".form-stack");
      var $label = $target.find(".stl-message");
      var $info = $target.find(".stl-info");
      var $info2 = $target.find(".stl-info2");
      $info.removeClass("stl-info-hide");
      this.suspiciousTrafficValue = evt.target.value;
      if (this.suspiciousTrafficValue !== "STL_NONE") {
        $info.removeClass("stl-info-hide");
        $info2.addClass("stl-info-hide2");
      } else {
        $info.addClass("stl-info-hide");
        $info2.removeClass("stl-info-hide2");
      }
      this.suspiciousTrafficValueChanged = true;
      this.isFormElementsChanged = true;
      $label.text(this.suspiciousTrafficDescription[this.suspiciousTrafficValue]);
    },
    setStlDropdownDisabledState: function (evt, state) {
      var $target = $(evt.target).closest(".form-stack");
      var $label = $target.find(".stl-message");
      var $dropdown = $target.find(".stl-dropdown");
      if (state === true) {
        $dropdown[0].placeholder = "Select One";
        $dropdown[0].value = "";
        $label.text("");
      }
      $dropdown.attr("disabled", state);
    },
    onStlOrgDefaultClick: function (evt) {
      var $target = $(evt.target).closest(".form-stack");
      var $info = $target.find(".stl-info");
      var $info2 = $target.find(".stl-info2");
      var $dropdown = $target.find(".stl-dropdown");
      $dropdown[0].error = false;
      $info.addClass("stl-info-hide");
      $info2.addClass("stl-info-hide2");
      this.setStlDropdownDisabledState(evt, true);
      this.isFormElementsChanged = true;
      this.suspicious_traffic_level_default = true;
      this.suspiciousTrafficValue = "STL_STANDARD";
      this.model.set({
        override_suspicious_traffic_filter: "0"
      });
    },
    onStlFlagLevelClick: function (evt) {
      this.setStlDropdownDisabledState(evt, false);
      this.isFormElementsChanged = true;
      this.suspicious_traffic_level_default = false;
      this.model.set({
        override_suspicious_traffic_filter: "1"
      });
    },
    onTpaRadioClick: function (evt) {
      var tpaRadioValue = evt.currentTarget.getAttribute("value");
      const tpasCampLevelTargetingSetting = tpaRadioValue === "0" ? "0" : "1";
      this.model.set({
        restrict_targeting_to_deterministic_id: tpasCampLevelTargetingSetting
      });
    },
    onRestrictSameDeviceRadioClick: function (evt) {
      const sameDeviceRadioValue = evt.currentTarget.getAttribute("value");
      if (sameDeviceRadioValue === "enabled" || sameDeviceRadioValue === "deterministic") {
        this.model.set({
          connected_id_type: "DETERMINISTIC_ONLY",
          restrict_targeting_to_same_device_id: "0"
        });
      } else if (sameDeviceRadioValue === "hybrid") {
        this.model.set({
          connected_id_type: "DETERMINISTIC_FIRST",
          restrict_targeting_to_same_device_id: "0",
          bid_min_devices: 1
        });
      } else {
        this.model.set({
          connected_id_type: "",
          restrict_targeting_to_same_device_id: "1",
          bid_min_devices: 1
        });
      }
      this.renderPartial(".same-device-message");
      this.renderPartial(".bid-min-devices-op");
    },
    onViewabilityChecked: function (e) {
      var previousValue = this.model.get("use_viewability_reporting");
      var newValue = e.currentTarget.value;
      if (previousValue !== newValue) {
        this.model.set({
          use_viewability_reporting: newValue
        });
        if (this.selectCreated) {
          const partialsToRender = [".form-select.vendor", ".form-select.tag-type"];
          if (!e.currentTarget.value) {
            this.model.unset("viewability_type");
            this.model.unset("viewability_vendor_id");
            this.model.unset("viewability_sample_rate");
            partialsToRender.push(".form-select.measurement-type", ".select-vendor-inline-message");
          }
          this.renderManyPartials(partialsToRender);
        }
      }
    },
    onViewabilityDropdownSelected: function (e) {
      if (e.target.value) {
        e.target.removeAttribute("original-title");
        e.target.classList.remove("error");
      }
    },
    onVendorSelected: function (e) {
      const selectedVendorID = e.target.value;
      const lastSelectedVendorID = this.model.get("viewability_vendor_id");
      const partialsToRender = [".form-select.tag-type", ".form-select.measurement-type", ".select-vendor-inline-message"];
      this.model.set({
        viewability_vendor_id: selectedVendorID
      });
      if (!moatAutoTagPermitted) {
        switch (moatVendorID) {
          case selectedVendorID:
            this.model.set({
              viewability_type: "BYOV"
            });
          case lastSelectedVendorID:
            this.renderManyPartials(partialsToRender);
            break;
        }
      }
      this.onViewabilityDropdownSelected(e);
    },
    onTagTypeSelected: function (e) {
      var value = e.target.value;
      var currentSampleRate = this.model.get("viewability_sample_rate");
      this.model.set({
        viewability_type: e.target.value
      });
      if (!currentSampleRate && value === "AUTO_TAG") {
        this.model.set({
          viewability_sample_rate: "0.05"
        });
      } else if (value === "BYOV") {
        this.model.unset("viewability_sample_rate");
      }
      this.renderManyPartials([".form-select.measurement-type", ".select-vendor-inline-message"]);
      this.onViewabilityDropdownSelected(e);
    },
    serializeSameDeviceRestriction: function (returnObj) {
      const connectedIDtype = this.model.get("connected_id_type") || this.orgModel.get("connected_id_type") || "DETERMINISTIC_ONLY";
      const sameDeviceID = this.model.get("restrict_targeting_to_same_device_id") || this.orgModel.get("restrict_targeting_to_same_device_id") || "0";
      if (sameDeviceID === "0") {
        if (connectedIDtype === "DETERMINISTIC_FIRST") {
          returnObj.sameDeviceValue = "hybrid";
        } else {
          returnObj.sameDeviceValue = "deterministic";
        }
      } else {
        returnObj.sameDeviceValue = "disabled";
      }
      return returnObj;
    },
    serialize: function () {
      let defaultVendor, rangeObj;
      var self = this;
      var dateFormat = "MM/dd/yy";
      var model = this.model;
      var canCreateCampaign = this.id ? T1Permissions.check("campaign.edit", model) : T1Permissions.check("campaign.create");
      const canSetCurrency = this.canSetCurrency;
      var modelJson = model.toJSON();
      const timezone = this.timezones && this.timezones.getByZoneName(this.zoneName);
      var markupCurrency = T1.Utils.markupCurrency;
      var isMinimizeSetOnAdvertiser = modelJson.advertiser && modelJson.advertiser.minimize_multi_ads === "1";
      var isProgrammaticGuaranteed = self.isProgrammaticGuaranteed || false;
      var advertiserJSON = modelJson.advertiser ? modelJson.advertiser : {};
      var obj = {
        attributionVisible: false,
        canCreateCampaign: canCreateCampaign,
        t1supportEmail: "t1_support@mediamath.com",
        goalTypeOptions: goalTypes,
        frequencyTypeOptions: dropdownList.frequency_type.options,
        frequencyIntervalOptions: dropdownList.frequency_interval.options,
        attrConversionOptions: dropdownList.attrConversionOptions.options,
        attrConversionWithMinOptions: dropdownList.attrConversionWithMinOptions.options,
        meritEventOptions: dropdownList.meritEventOptions.options,
        dateTimeDisplay: getStartEndDatesDisplay({
          startDate: this.startDate,
          endDate: this.endDate,
          dateFormat: this.displayDateFormat,
          timeFormat: this.displayTimeFormat
        }),
        timeZone: timezone && timezone.toJSON() || {},
        startDateAsString: modelJson.start_date ? Date.parse(modelJson.start_date).toString(dateFormat) : undefined,
        endDateAsString: modelJson.end_date ? Date.parse(modelJson.end_date).toString(dateFormat) : undefined,
        advertiser_name: this.advertiserName || advertiserJSON.name || "ADVERTISER",
        ad_server_name: modelJson.ad_server ? modelJson.ad_server.name : "--",
        use_campaign_freq_cap: this.useCampaignFreqCap,
        hasStarted: this.hasStarted,
        isNewCampaign: modelJson.id === undefined,
        pv_pct: "100.00",
        conversion_variable_minutes: 1,
        conversion_variable_minutes_select: "minutes",
        conversion_variable_input: "1",
        conversion_type: "variable",
        bidMinDevices_val: modelJson.bid_min_devices === "2",
        dailySpendCapOptions: dropdownList.daily_spend_cap.options,
        monetaryPacingOptions: dropdownList.monetary_pacing.options,
        impressionPacingOptions: dropdownList.impression_pacing.options,
        dailyMonetaryCapOptions: dropdownList.daily_monetary_cap.options,
        dailyImpressionCapOptions: dropdownList.daily_impression_cap.options,
        nielsenGenderOptions: dropdownList.nielsen_gender.options,
        nielsenAgesFemaleOptions: dropdownList.nielsen_ages_female.options,
        nielsenAgesMaleOptions: dropdownList.nielsen_ages_male.options,
        nielsenCoutryCode: dropdownList.nielsen_country_code.options,
        use_campaign_daily_spend: this.useCampaignDailySpend,
        is_goal_type_roi: modelJson.goal_type === "roi",
        showMinimizeAdvertiserTxt: isMinimizeSetOnAdvertiser,
        isMinimizeSetOnAdvertiser: isMinimizeSetOnAdvertiser,
        isMinimizeAdCollisionSelected: modelJson.minimize_multi_ads === "1" || isMinimizeSetOnAdvertiser,
        useViewabilityVideoEnabled: this.useViewabilityVideoEnabled,
        useViewabilityReportingPermitted: this.useViewabilityReportingPermitted,
        useMTALite: this.useMTALite,
        isProgrammaticGuaranteed: isProgrammaticGuaranteed
      };
      var noData = "&nbsp;--";
      var noAmountData = "$&nbsp;--";
      var campaignDefaults = {
        name: "Campaign Name",
        advertiser_name: "Advertiser Name",
        id: noData,
        total_budget: noAmountData,
        total_impression_budget: noAmountData,
        impression_cap_amount: "",
        goal_value: noAmountData,
        startDateAsString: noData,
        endDateAsString: noData
      };
      var returnObj = $.extend(obj, modelJson);
      var serviceType = modelJson.service_type || "";
      var ctdReportJson = getCtdReportJsonObjByModel(model);
      var currencyCode = model.get("currency_code");
      var currencySymbol = getCurrencySymbol(currencyCode);
      var isCustomAttribution = modelJson.has_custom_attribution === "1";
      const decimals = 2;
      selectCurrCode = currencyCode;
      this.suspicious_traffic_level = this.getSuspiciousTrafficLevel(model.get("override_suspicious_traffic_filter"), model.get("suspicious_traffic_filter_level"));
      this.suspicious_traffic_level_org_default = this.getSuspiciousTrafficLevel(this.orgModel.get("override_suspicious_traffic_filter"), this.orgModel.get("suspicious_traffic_filter_level"));
      if (this.isNewCampaign) {
        this.suspicious_traffic_level_default = true;
      } else {
        this.suspicious_traffic_level_default = this.suspicious_traffic_level === "STL_STANDARD";
      }
      if (!returnObj.use_campaign_freq_cap) {
        returnObj.frequency_type = "";
        returnObj.frequency_amount = "";
        returnObj.frequency_interval = "";
      }
      returnObj.freqNoLimit = returnObj.frequency_type === "no-limit";
      if (modelJson.margin_type) {
        returnObj.margin_type_fixed = modelJson.margin_type === "fixed";
        returnObj.margin_fixed_pct = returnObj.margin_type_fixed ? modelJson.margin_fixed_pct : "";
      } else {
        returnObj.margin_type_fixed = true;
        returnObj.margin_fixed_pct = 0;
      }
      returnObj.isSelfServiceType = serviceType === "" ? true : modelJson.service_type === "SELF";
      returnObj.spend_cap_automatic = modelJson.id === undefined ? "auto" : modelJson.spend_cap_automatic === "1" ? "auto" : "manual";
      returnObj.impression_cap_automatic = modelJson.id === undefined ? "auto" : modelJson.impression_cap_automatic === "1" ? "auto" : "manual";
      returnObj.monetaryNoCap = returnObj.spend_cap_type === "no-limit";
      returnObj.monetarySpendType = self.spend_cap_type === "t1-optimized";
      returnObj.impression_cap_type = modelJson.impression_cap_type ? modelJson.impression_cap_type : "no-limit";
      returnObj.impression_cap_amount = modelJson.impression_cap_amount ? $.trim(formatsRolledUpNumbersNoAbbreviation(modelJson.impression_cap_amount)) : "";
      returnObj.impressionNoCap = returnObj.impression_cap_type === "no-limit";
      returnObj.mm_goal_performance = markupCurrency(ctdReportJson.mm_goal_performance);
      if (isCustomAttribution) {
        returnObj.conv_attribution_value = returnObj.custom_attribution_source === "3rd Party Attribution" ? "3rd-party" : "mta-lite";
      } else {
        returnObj.conv_attribution_value = "t1";
      }
      if (this.isNewCampaign) {
        returnObj.conv_attribution_value = "t1";
      }
      returnObj.isPCWindowChecked = true;
      returnObj.isPVWindowChecked = true;
      if (modelJson.merit_pixel_id && modelJson.merit_pixel_id !== "") {
        modelJson.pc_window_minutes = modelJson.pc_window_minutes || this.lastPcMinutes;
        modelJson.pv_window_minutes = modelJson.pv_window_minutes || this.lastPvMinutes;
        returnObj.isPCWindowChecked = modelJson.pc_window_minutes && modelJson.pc_window_minutes !== "";
        returnObj.isPVWindowChecked = modelJson.pv_window_minutes && modelJson.pv_window_minutes !== "";
        rangeObj = convertMinutesToRange(modelJson.pc_window_minutes, decimals);
        returnObj.pc_window_minutes = rangeObj.rangeValue;
        returnObj.pc_window_minutes_select = rangeObj.selectValue;
        rangeObj = convertMinutesToRange(modelJson.pv_window_minutes, decimals);
        returnObj.pv_window_minutes = rangeObj.rangeValue;
        returnObj.pv_window_minutes_select = rangeObj.selectValue;
      }
      if (/^(cpe|cpa|roi)$/.test(this.goalType)) {
        returnObj.conversion_type = getSelectedMeritValue(returnObj.conversion_type);
        returnObj.isVariableMeritInput = returnObj.conversion_type === "variable";
        if (returnObj.isVariableMeritInput) {
          const minutes = model.get("last_conversion_variable_minutes") || returnObj.conversion_variable_minutes;
          model.set({
            last_conversion_variable_minutes: minutes
          });
          rangeObj = convertMinutesToRange(minutes, 0);
          returnObj.conversion_variable_input = rangeObj.rangeValue;
          returnObj.conversion_variable_minutes_select = rangeObj.selectValue;
        }
        model.set({
          conversion_variable_input: returnObj.conversion_variable_input
        });
        model.set({
          conversion_variable_minutes_select: returnObj.conversion_variable_minutes_select
        });
      }
      switch (modelJson.dynamic_budget_allocation_type) {
        case "SBA_AUTO_ALLOCATION":
          returnObj.isDbaAuto = true;
          break;
        case "SBA_RECOMMENDATIONS_ONLY":
          returnObj.isDbaManual = true;
          break;
        case "OFF":
        default:
          returnObj.isDbaOff = true;
          break;
      }
      returnObj.dynamic_budget_allocation_type = modelJson.dynamic_budget_allocation_type ? modelJson.dynamic_budget_allocation_type : "OFF";
      if (returnObj.dynamic_budget_allocation_type === "OFF") {
        this.$(".dba-inline-message").hide();
      } else {
        this.$(".dba-inline-message").show();
      }
      if (modelJson.status !== undefined) {
        returnObj.isStatusActive = modelJson.status === "1";
        returnObj.campaign_status = function (target) {
          var status = modelJson.status === "1" ? "active" : "inactive";
          if (target) {
            target.removeClass("active").removeClass("inactive");
            target.addClass(status);
          }
          return status;
        };
      } else {
        returnObj.isStatusActive = true;
      }
      returnObj.campaignDefaults = campaignDefaults;
      returnObj.goal_type_text = goalTypeText[returnObj.goal_type];
      returnObj.goalValuePCT = returnObj.goal_type === "vcr" || returnObj.goal_type === "ctr" || returnObj.goal_type === "viewability_rate";
      if (returnObj.pv_pct && returnObj.pv_pct !== "") {
        returnObj.pv_pct = returnObj.pv_pct.substring(0, returnObj.pv_pct.indexOf("."));
      }
      returnObj.roi_type = returnObj.goal_type === "roi";
      returnObj.ctr_type = returnObj.goal_type === "ctr";
      returnObj.vcr_type = returnObj.goal_type === "vcr";
      returnObj.vrate_type = returnObj.goal_type === "viewability_rate";
      returnObj.vcpm_type = returnObj.goal_type === "vcpm";
      returnObj.msgForManagedBySection = {
        infoBoxMessageLines: ["Thank you for selecting a MediaMath-managed campaign. A MediaMath team member will contact your " + "sales contact regarding this campaign within 3 business days.", "PLEASE NOTE: This campaign will not execute until you have received notification from the MediaMath Team."]
      };
      returnObj.msgForMeritPixelSection = {
        infoBoxMessageLines: [`Changing your merit pixel will zero out this campaign's conversion data for the previous 7 days. ` + "All future reported conversions will be for the newly selected pixel."]
      };
      returnObj.msgForROIGoalTypeSection = {
        infoBoxMessageLines: ["Please enter the average order value (AOV) for conversions attributed to this campaign. " + "The ROI desired will be available for entry at the strategy level."],
        infoBoxClassName: returnObj.roi_type ? "show-on-load" : ""
      };
      returnObj.msgForVCRGoalTypeSection = {
        infoBoxMessageLines: ["Video Completion Rate (VCR) Campaigns optimize to the percentage of impressions played to completion."],
        infoBoxClassName: returnObj.vcr_type ? "show-on-load" : ""
      };
      returnObj.msgForViewabilityGoalTypeSection = {
        infoBoxMessageLines: ["Viewability Rate Campaigns optimize to the percentage of impressions deemed " + "viewable by the post-bid reporting partner you select below."],
        infoBoxClassName: returnObj.vrate_type ? "show-on-load" : ""
      };
      returnObj.msgForVCPMGoalTypeSection = {
        infoBoxMessageLines: ["Viewable CPM (vCPM) Campaigns seek to minimize the effective cost of viewable impressions. " + "Viewability will be determined by the post-bid reporting partner you select below."],
        infoBoxClassName: returnObj.vcpm_type ? "show-on-load" : ""
      };
      returnObj.canSetCurrency = canSetCurrency;
      returnObj.currencyList = getCurrencyList({
        selectedId: currencyCode
      });
      returnObj.currency_code = currencyCode;
      returnObj.currency_symbol = currencySymbol;
      returnObj.suspicious_traffic_level = this.suspiciousTrafficValue = this.suspicious_traffic_level;
      returnObj.suspicious_traffic_description = this.suspiciousTrafficDescription[this.suspicious_traffic_level];
      returnObj.suspiciousTrafficOrgDefaultValue = this.suspiciousTrafficShortDescription[this.suspicious_traffic_level_org_default];
      returnObj.restrict_targeting_to_deterministic_id = this.orgModel.get("restrict_targeting_to_deterministic_id");
      returnObj.tpasValue = returnObj.restrict_targeting_to_deterministic_id;
      this.serializeSameDeviceRestriction(returnObj);
      if (modelJson.restrict_targeting_to_deterministic_id) {
        returnObj.tpasValue = modelJson.restrict_targeting_to_deterministic_id;
      }
      returnObj.pcPvTooHigh = false;
      returnObj.advertiserFreqCap = advertiserJSON.id && advertiserJSON.frequency_type && advertiserJSON.frequency_type !== "no-limit";
      if (returnObj.advertiserFreqCap) {
        returnObj.advertiserFrequencyType = advertiserJSON.frequency_type.toLowerCase && advertiserJSON.frequency_type.toLowerCase() === "asap" ? advertiserJSON.frequency_type.toUpperCase() : advertiserJSON.frequency_type;
        returnObj.advertiserFrequencyAmount = advertiserJSON.frequency_amount;
        returnObj.advertiserFrequencyInterval = advertiserJSON.frequency_interval;
      }
      returnObj.use_viewability_reporting = Boolean(returnObj.use_viewability_reporting || returnObj.viewability_type);
      returnObj.viewabilityVendorsArr = this.vendorList.map(vendorModel => ({
        value: vendorModel.get("id"),
        name: vendorModel.get("name")
      }));
      for (let i = 0, length = returnObj.viewabilityVendorsArr.length; i < length; i++) {
        const vendor = returnObj.viewabilityVendorsArr[i];
        const vendorID = vendor.value;
        if (vendorID === defaultViewabilityVendorID) {
          defaultVendor = vendor;
        }
        if (!this.isVendorAllowed[vendorID]) {
          returnObj.viewabilityVendorsArr.splice(i--, 1);
          length--;
        }
      }
      returnObj.viewabilityVendorPlaceholder = defaultVendor ? defaultVendor.name : "Select Vendor";
      if (!returnObj.viewability_vendor_id && defaultVendor) {
        returnObj.viewability_vendor_id = defaultVendor.value;
        this.model.set({
          viewability_vendor_id: defaultVendor.value
        });
      }
      returnObj.isAutoTagPermitted = moatAutoTagPermitted || returnObj.viewability_vendor_id !== moatVendorID;
      returnObj.isViewabilityVendorByov = returnObj.viewability_type === "BYOV";
      returnObj.isViewabilityVendorAuto = returnObj.viewability_type === "AUTO_TAG";
      if (returnObj.isViewabilityVendorByov && returnObj.viewability_sample_rate) {
        delete returnObj.viewability_sample_rate;
        this.model.unset("viewability_sample_rate");
      }
      returnObj.useAllocationPacing = useAllocationPacing;
      returnObj.useProgrammaticGuaranteed = this.useProgrammaticGuaranteedFlag;
      returnObj.useDynamicBudgetAllocation = this.useDynamicBudgetAllocation;
      returnObj.isCookielessHidden = isCookielessHidden;
      returnObj.canEditMargins = canEditMargins;
      returnObj.isDeterministicAndHybridSelected = returnObj.sameDeviceValue === "deterministic" || returnObj.sameDeviceValue === "hybrid";
      returnObj.isDeterministicSelected = returnObj.sameDeviceValue === "deterministic";
      const nielsenData = this.nielsenDemographicsModel.get("providerData") || {};
      returnObj.nielsen_enabled = this.nielsenDemographicsModel.get("nielsen_enabled");
      returnObj.nielsenAgesFemaleOptions.concat(returnObj.nielsenAgesMaleOptions).forEach(option => {
        option.selected = nielsenData.demographics && nielsenData.demographics.includes(parseInt(option.value, 10));
      });
      if (returnObj.nielsen_gender_male) {
        returnObj.nielsenAgesMale = {
          type: "age",
          options: returnObj.nielsenAgesMaleOptions,
          selectedNumberLabel: this.getSelectedItemLabel(returnObj.nielsenAgesMaleOptions, returnObj.nielsenAgesMaleOptions.filter(option => option.selected))
        };
      }
      if (returnObj.nielsen_gender_female) {
        returnObj.nielsenAgesFemale = {
          type: "age",
          options: returnObj.nielsenAgesFemaleOptions,
          selectedNumberLabel: this.getSelectedItemLabel(returnObj.nielsenAgesFemaleOptions, returnObj.nielsenAgesFemaleOptions.filter(option => option.selected))
        };
      }
      returnObj.nielsenCountryCodes = obj.nielsenCoutryCode;
      returnObj.nielsen_primary_country = this.nielsenDemographicsModel.get("nielsen_primary_country");
      returnObj.isNpcDisabled = !this.nielsenDemographicsModel.get("nielsen_primary_country") === "";
      returnObj.showNdar = useNdarPermitted;
      returnObj.isPoliticalCampaign = modelJson.political === "1";
      returnObj.isAdsTxtUserControlsEnabled = T1Permissions.check("feature", "ads_txt_user_controls_campaign_strategy");
      returnObj.ads_txt_verified = modelJson.ads_txt_verified ? modelJson.ads_txt_verified : "INHERITED";
      returnObj.inherited_ads_txt_verified_origin_entity_type = "Advertiser";
      return returnObj;
    }
  });
  return T1.Form.ViewUtils.extend(CampaignGeneralDetailsView);
});
