function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
define('collections/strategySupplySources',["jQuery", "Underscore", "T1", "T1UtilsAsync", "T1Collection", "T1Permissions", "collections/mediaDeals", "collections/mediaDealGroups", "collections/strategyDealGroups"], function ($, _, T1, T1UtilsAsync, T1Collection, T1Permissions, Deals, DealGroups, StrategyDealGroups) {
  "use strict";

  var originalInitialize;
  var collection = T1Collection.extend({
    canCache: false,
    filterConfiguration: {
      filters: [{
        entity: "strategy",
        fetchOnChange: false
      }],
      enableEvents: false
    },
    action: "supplies",
    urlString: "strategies",
    fixture: "-strategySupplySources",
    isSaving: false,
    url: function () {
      var originalString = this.urlString;
      var url;
      if (this.id === undefined) {
        throw new Error("Strategy id needs to be set in id property of collection to construct URL");
      }
      this.urlString = `${originalString}/${this.id}/${this.action}`;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    runOnAllDisabled: function (runOnAll, supplyType) {
      if (supplyType === "supply_source") {
        return !T1.Utils.valueToBool(runOnAll.all_exchanges);
      } else if (supplyType === "deal" || supplyType === "deal_group") {
        return !T1.Utils.valueToBool(runOnAll.all_pmp);
      }
      return true;
    },
    buildPostObj: function (supplyType) {
      let supplyTypeData = supplyType;
      if (supplyType === "site_placement") {
        supplyTypeData += "s";
      }
      return this.models.filter(model => model.get("type") === supplyType || model.get("entity_type") === supplyType).reduce((result, model, index) => {
        result[`${supplyTypeData}.${index += 1}.id`] = model.id;
        return result;
      }, {});
    },
    fetch: function (...args) {
      const [opts] = args;
      const targetAllFlagEnabled = this.targetAllFlagEnabled = T1Permissions.check("feature", "target_all_supply");
      const runOnAllPMP = this.strategy.get("run_on_all_pmp") === "1";
      const runOnAllExchanges = this.strategy.get("run_on_all_exchanges") === "1";
      if (!targetAllFlagEnabled) {
        if (this.strategy && (runOnAllPMP || runOnAllExchanges)) {
          return T1Collection.prototype.fetch.apply(this, args);
        }
      }
      if (opts) {
        this.isAdding = opts.add;
        this.successCallback = $.proxy(opts.success, this);
        opts.success = null;
      }
      const protoFetch = T1Collection.prototype.fetch.apply(this, args);
      protoFetch.then(() => {
        if (targetAllFlagEnabled) {
          this.setTypeIds(this);
          if (runOnAllPMP) {
            this.cachedSupplySources = this.models.slice();
            this.mixInSupplySources();
          } else {
            T1UtilsAsync.linkPromises([this.fetchDeals(), this.fetchDealGroups()]).then(() => {
              this.cachedSupplySources = this.models.slice();
              this.mixInSupplySources();
            });
          }
        } else {
          this.setModelIds(this);
          if (this.models[0] && (this.models[0].get("type") === "supply_source" || this.models[0].get("type") === "site_placement")) {
            this.successCallback(this.models);
          } else {
            T1UtilsAsync.linkPromises([this.fetchDeals(), this.fetchDealGroups()]).then(() => {
              this.cachedSupplySources = this.models.slice();
              this.mixInSupplySources();
              this.setContentType("deals");
            });
          }
        }
      });
      return protoFetch;
    },
    setModelIds: function (coll = []) {
      return (coll.models || coll).map(this.setModelId);
    },
    setModelId: function (model) {
      model.set({
        modelId: model.get("id")
      });
    },
    setTypeIds: function (coll = []) {
      return (coll.models || coll).map(this.setTypeId);
    },
    setTypeId: function (model) {
      const type = model.get("type") || model.get("entity_type");
      const id = model.get("id");
      model.set({
        modelId: id,
        id: `${type}-${id}`
      });
      return model;
    },
    setNormalIds: function (coll = []) {
      return (coll.models || coll).map(this.setNormalId);
    },
    setNormalId: function (model) {
      const id = model.get("modelId");
      if (id) {
        model.set({
          id: id,
          modelId: model.get("id")
        });
      }
      return model;
    },
    generateUnpermissionedGridData: function () {
      return this.unpermissionedDeals.map(deal => ({
        dealName: deal.get("name"),
        dealId: deal.get("deal_identifier")
      }));
    },
    fetchDealGroups: function () {
      const self = this;
      const deferred = T1UtilsAsync.makeDeferral();
      const campaign = this.strategy.get("campaign");
      const currencyCode = campaign.get("currency_code");
      const dealGroups = new StrategyDealGroups(false, {
        fetchOptions: {
          full: "*",
          currency_code: currencyCode
        },
        isPagenated: false
      });
      dealGroups.id = this.id;
      dealGroups.fetchAllPages({
        success: function (data) {
          self.mixInDealGroups(data, deferred);
        }
      });
      return deferred.promise;
    },
    fetchDeals: function () {
      var self = this;
      const deferred = T1UtilsAsync.makeDeferral();
      var campaign = this.strategy.get("campaign");
      var currencyCode = campaign.get("currency_code");
      const organizationId = campaign.get("advertiser").get("agency").get("organization_id");
      const useV2 = T1Permissions.check("feature", "deals_v2_api");
      var deals = new Deals(false, {
        fetchOptions: _objectSpread({
          full: "*",
          strategy_id: self.id,
          currency_code: currencyCode
        }, useV2 && {
          "owner.organization_id": organizationId
        }),
        isPaginated: false
      });
      deals.fetchAllPages({
        success: function (data) {
          if (self.targetAllFlagEnabled) {
            self.setTypeIds(data);
          } else {
            self.setModelIds(data);
          }
          self.addEntityToCollection(data);
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    fetchPermissionedDeals: function (advertiserId, currencyCode, promise, isPanelView) {
      const dealIdArray = [];
      const models = this.models.filter(deal => deal.get("type") && deal.get("type") === "deal");
      const dealIds = this.targetAllFlagEnabled ? models.map(deal => {
        const dealType = deal.get("type");
        if (deal.id.indexOf(dealType) === 0) {
          return deal.id.slice(dealType.length + 1);
        }
        return deal.id;
      }) : models.map(deal => deal.id);
      const maxQueryLen = 300;
      let numberOfDealFetches = Math.ceil(dealIds.length / maxQueryLen);
      while (numberOfDealFetches--) {
        const tempDealIdArray = dealIds.splice(0, maxQueryLen);
        const qString = `(${tempDealIdArray.join(",")})`;
        dealIdArray.push(qString);
      }
      const organizationId = this.strategy.get("campaign").get("advertiser").get("agency").get("organization_id");
      const useV2 = T1Permissions.check("feature", "deals_v2_api");
      const dealsArray = dealIdArray.map(qString => new Deals(false, {
        fetchOptions: _objectSpread({
          full: "*",
          q: qString,
          "permissions.advertiser_id": advertiserId,
          currency_code: currencyCode
        }, useV2 && {
          "owner.organization_id": organizationId
        }),
        isPaginated: false
      }));
      this.permissionedDeals = [];
      const dealFetchArray = dealsArray.map(dealCollection => {
        const deferred = T1UtilsAsync.makeDeferral();
        dealCollection.fetchAllPages({
          success: permissionedDeals => {
            this.permissionedDeals = this.permissionedDeals.concat(permissionedDeals.models);
            deferred.resolve();
          }
        });
        return deferred.promise;
      });
      T1UtilsAsync.linkPromises(dealFetchArray).then(() => {
        if (this.permissionedDeals.length < models.length) {
          this.hasUnpermissionedDeals = true;
          this.unpermissionedDeals = models.filter(deal => {
            const dealType = deal.get("type");
            const idValue = this.targetAllFlagEnabled && deal.id.indexOf(dealType) === 0 ? deal.id.slice(dealType.length + 1) : deal.id;
            return !this.permissionedDeals.some(permissionedDeal => parseInt(permissionedDeal.id, 10) === parseInt(idValue, 10));
          });
          const otherModels = this.models.filter(model => model.get("type") !== "deal").concat(this.permissionedDeals);
          this.reset(otherModels);
          this.permissionedDealCount = this.length;
          if (isPanelView) {
            T1.EventHub.publish("hasUnpermissionedDeals:updateBins");
          }
        }
        if (promise) {
          promise.resolve();
        }
      });
    },
    getPermissionedDeals: function () {
      return this.models.filter(deal => !this.unpermissionedDeals.some(unpermissionedDeal => parseInt(unpermissionedDeal.id, 10) === parseInt(deal.id, 10) && deal.type === "deal"));
    },
    containsPMPDeals: function () {
      const isPMPE = deal => deal.get("type") === "deal" || deal.get("entity_type") === "deal_group";
      return this.models.some(isPMPE) || Boolean(parseInt(this.strategy.attributes.run_on_all_pmp, 10));
    },
    mixInDealGroups: function (data, deferred) {
      const self = this;
      const dealGroupIdArray = [];
      const currencyCode = this.currencyCode;
      const dealGroupIds = data.map(item => item.id);
      const API_LIMIT = 100;
      const organizationId = this.strategy.get("campaign").get("advertiser").get("agency").get("organization_id");
      const useV2 = T1Permissions.check("feature", "deals_v2_api");
      let numberofDealGroupFetches = Math.ceil(dealGroupIds.length / API_LIMIT);
      this.dealGroups = [];
      while (numberofDealGroupFetches--) {
        const tempDealGroupIdArray = dealGroupIds.splice(0, API_LIMIT);
        const qString = `(${tempDealGroupIdArray.join(",")})`;
        dealGroupIdArray.push(qString);
      }
      const dealGroupColls = dealGroupIdArray.map(qString => new DealGroups(false, {
        fetchOptions: _objectSpread(_objectSpread({
          full: "*",
          q: qString,
          strategy_id: this.id
        }, useV2 && {
          "owner.organization_id": organizationId
        }), {}, {
          currency_code: currencyCode
        }),
        isPaginated: false
      }));
      const dealGroupFetches = dealGroupColls.map(dealGroupColl => {
        const partialDeferred = T1UtilsAsync.makeDeferral();
        dealGroupColl.fetchAllPages({
          success: function (response) {
            if (self.targetAllFlagEnabled) {
              self.setTypeIds(response);
            } else {
              self.setModelIds(response);
            }
            self.dealGroups = self.dealGroups.concat(response.models);
            self.addEntityToCollection(response);
            partialDeferred.resolve();
          }
        });
        return partialDeferred.promise;
      });
      T1UtilsAsync.linkPromises(dealGroupFetches).then(() => {
        deferred.resolve();
      });
    },
    addEntityToCollection: function (data) {
      if (data && data.length > 0) {
        this.add(data.models, {
          silent: true
        });
      }
    },
    mixInSupplySources: function () {
      this.loaded = true;
      if (this.successCallback) {
        this.successCallback(this.models);
      }
      if (!this.isAdding) {
        this.trigger("reset");
      } else {
        this.isAdding = null;
      }
    },
    saveStrategySupplySources: function (runOnAll, success, conflict, isPGStrategy) {
      if (this.targetAllFlagEnabled) {
        this.saveTargetAllSupplySources(runOnAll, success, conflict, isPGStrategy);
      } else {
        this.saveNormalSupplySources(runOnAll, success, conflict, isPGStrategy);
      }
    },
    disableRunOnAllPMP: function (conflict) {
      const additionalOptions = {
        data: {
          run_on_all_pmp: "0"
        }
      };
      const savePromise = T1UtilsAsync.makeDeferral();
      this.action = "deals";
      this.save(additionalOptions, {
        success: function () {
          savePromise.resolve();
        },
        conflict: conflict
      });
      return savePromise.promise;
    },
    saveNormalSupplySources: function (runOnAll, success, conflict) {
      const saveSupplySource = supplyType => {
        const additionalOptions = {};
        const supplySourceDeferred = T1UtilsAsync.makeDeferral();
        const isOpenSupply = supplyType === "supply_source";
        const notDeals = isOpenSupply || supplyType === "site_placement";
        this.action = isOpenSupply ? "supplies" : `${supplyType}s`;
        if (!notDeals) {
          this.reset(this[`${this.action}`]);
        }
        if (this.runOnAllDisabled(runOnAll, supplyType)) {
          additionalOptions.data = this.buildPostObj(supplyType);
          _.extend(additionalOptions.data, runOnAll);
        } else {
          additionalOptions.data = runOnAll;
        }
        this.save(additionalOptions, {
          success: function () {
            supplySourceDeferred.resolve();
          },
          conflict: conflict
        });
        return supplySourceDeferred.promise;
      };
      this.deals = this.models.filter(item => item.get("type") === "deal");
      this.deal_groups = this.models.filter(item => item.get("entity_type") === "deal_group");
      if (this.strategy && parseInt(this.strategy.get("run_on_all_pmp"), 10)) {
        this.hasUnpermissionedDeals = false;
        this.unpermissionedDeals = [];
        this.permissionedDeals = [];
        this.permissionedDealCount = 0;
      }
      if (this.contentType === "site_placement") {
        this.disableRunOnAllPMP(conflict).then(() => {
          saveSupplySource(this.contentType).then(() => {
            if (success) {
              success();
            }
          });
        });
      } else if (this.contentType !== "deals") {
        saveSupplySource(this.contentType).then(() => {
          if (success) {
            success();
          }
        });
      } else {
        const firstSupplySource = this.deals.length === 0 ? "deal_group" : "deal";
        const secondSupplySource = firstSupplySource === "deal_group" ? "deal" : "deal_group";
        saveSupplySource(firstSupplySource).then(() => saveSupplySource(secondSupplySource)).then(() => {
          this.reset(this.deals.concat(this.deal_groups));
          success();
        });
      }
    },
    saveTargetAllSupplySources: function (runOnAll, success, conflict, isPGStrategy) {
      const openRunOnAllObj = {
        all_exchanges: runOnAll.all_exchanges
      };
      const pmpeRunOnAllObj = {
        all_pmp: runOnAll.all_pmp
      };
      const allModels = this.models;
      const dupeModels = this.models.map(model => $.extend(true, {}, model));
      const supplySourceModels = this.setNormalIds(dupeModels.filter(item => item.get("type") === "supply_source"));
      const dealModels = this.setNormalIds(dupeModels.filter(item => item.get("type") === "deal"));
      const dealGroupModels = this.setNormalIds(dupeModels.filter(item => item.get("entity_type") === "deal_group"));
      const sitePlacementModels = this.setNormalIds(dupeModels.filter(item => item.get("type") === "site_placement"));
      const openColl = $.extend(true, {}, this.reset(supplySourceModels), {
        contentType: "supply_source"
      });
      const dealsColl = $.extend(true, {}, this.reset(dealModels), {
        contentType: "deal"
      });
      const saveSharedCollections = [$.extend(true, {}, this.reset(dealGroupModels), {
        contentType: "deal_group"
      }), $.extend(true, {}, this.reset(sitePlacementModels), {
        contentType: "site_placement"
      })];
      this.reset(allModels);
      if (this.strategy && parseInt(this.strategy.get("run_on_all_pmp"), 10)) {
        this.hasUnpermissionedDeals = false;
        this.unpermissionedDeals = [];
        this.permissionedDeals = [];
        this.permissionedDealCount = 0;
      }
      if (isPGStrategy) {
        dealsColl.saveTargetAllSupplySource(dealsColl.contentType, pmpeRunOnAllObj, conflict).then(() => {
          if (success) {
            success();
          }
        });
      } else {
        openColl.saveTargetAllSupplySource(openColl.contentType, openRunOnAllObj, conflict).then(() => {
          dealsColl.saveTargetAllSupplySource(dealsColl.contentType, pmpeRunOnAllObj, conflict).then(() => {
            const savePromises = saveSharedCollections.map(coll => {
              if (coll.contentType === "deal_group" && Number(runOnAll.all_pmp)) {
                return undefined;
              }
              return coll.saveTargetAllSupplySource(coll.contentType, {}, conflict);
            });
            T1UtilsAsync.linkPromises(savePromises).then(() => {
              if (success) {
                success();
              }
            });
          });
        });
      }
    },
    filterSupplySharedColl: function (garbageFilteredColl) {
      const supplySharedColl = this.models.filter(model => model.get("type") === "supply_source");
      this.remove(supplySharedColl, {
        silent: true
      });
      const filteredSharedColl = supplySharedColl.filter(model => garbageFilteredColl.get(model.id));
      this.add(filteredSharedColl, {
        silent: true
      });
    },
    saveTargetAllSupplySource: function (supplyType, runOnAll, conflict) {
      const additionalOptions = {};
      const supplySourceDeferred = T1UtilsAsync.makeDeferral();
      const isSupplySourceType = supplyType === "supply_source";
      let shouldSaveModels;
      if (supplyType === "deal") {
        shouldSaveModels = !T1.Utils.valueToBool(runOnAll.all_pmp) && this.models.length;
      } else if (supplyType === "supply_source") {
        shouldSaveModels = !T1.Utils.valueToBool(runOnAll.all_exchanges) && this.models.length;
      }
      this.action = isSupplySourceType ? "supply_sources" : `${supplyType}s`;
      if ($.isEmptyObject(runOnAll) || shouldSaveModels) {
        additionalOptions.data = this.buildPostObj(supplyType);
      } else {
        additionalOptions.data = runOnAll;
      }
      this.save(additionalOptions, {
        success: function () {
          supplySourceDeferred.resolve();
        },
        conflict: function () {
          supplySourceDeferred.resolve();
          conflict();
        }
      });
      return supplySourceDeferred.promise;
    },
    saveFoldTargets: function (view, statusInvalidCallback) {
      var stratTargetDims = this.strategyTargetDimensions;
      var targetDims = stratTargetDims.get("target_dimensions");
      var id = "FOLD";
      var foldDims = targetDims.get(id);
      var defaultFoldModel = {
        INCLUDE_OP: "OR",
        EXCLUDE_OP: "AND",
        target_value: [],
        code: id,
        id: id
      };
      var deferred = $.Deferred();
      if (!foldDims) {
        targetDims.add(defaultFoldModel);
        foldDims = targetDims.get(id);
      }
      foldDims.set({
        target_value: this.foldTargetValues.models
      });
      function endPostingHanlder(hasErr, emptyTargetValues) {
        deferred.resolve({
          hasErr: hasErr === true,
          emptyTargetValues: emptyTargetValues
        });
      }
      stratTargetDims.saveTargetValues(endPostingHanlder, function () {
        if (statusInvalidCallback) {
          statusInvalidCallback([], "You will be wiping out all target values!", undefined, undefined, {
            wipeTargetValues: true
          });
        } else {
          endPostingHanlder(true, true);
        }
      }, function () {
        endPostingHanlder(true);
      }, undefined, view);
      return deferred.promise();
    },
    resetContentType: function () {
      this.contentType = this.previousContentType;
    },
    setContentType: function (type) {
      var isImproperlyFormattedData = type === "supply_sources";
      if (isImproperlyFormattedData) {
        type = "supply_source";
      }
      if (type !== this.contentType) {
        this.previousContentType = this.contentType;
      }
      this.contentType = type;
    },
    filterGarbageData: function () {
      let result;
      if (this.targetAllFlagEnabled) {
        result = this.models.filter(model => {
          if (model.get("type") === "supply_source") {
            return model.get("rtb_type");
          }
          return true;
        });
        this.reset(result, {
          silent: true
        });
      } else {
        switch (this.contentType) {
          case "supply_source":
            result = this.models.filter(model => model.get("type") === this.contentType && model.get("rtb_type") !== undefined);
            break;
          case "deals":
            result = this.models.filter(model => model.get("type") === "deal" || model.get("entity_type") === "deal_group");
            break;
          case "site_placement":
            result = this.models.filter(model => model.get("type") === this.contentType);
            break;
        }
        this.reset(result, {
          silent: true
        });
      }
    },
    parse: function (data) {
      var entityAry = data.entity.entity;
      var modelArray = [];
      if (!entityAry) {
        return modelArray;
      }
      entityAry = T1.Utils.objectTOArray(entityAry);
      modelArray = T1Collection.prototype.parse.call(this, {
        entities: {
          entity: entityAry,
          count: entityAry.length
        }
      });
      return modelArray;
    }
  });
  originalInitialize = collection.prototype.initialize;
  collection.prototype.initialize = function (models, options) {
    options = options || {};
    options.isPaginated = false;
    return originalInitialize.call(this, models, options);
  };
  T1.Models.StrategySupplySources = collection;
  return T1.Models.StrategySupplySources;
});
