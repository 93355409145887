function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
define('models/agency',["jQuery", "T1", "T1Model", "Backbone", "models/organization"], function ($, T1, T1Model, Backbone) {
  T1.Models.Agency = T1Model.extend({
    url: "agencies/",
    relations: [{
      type: Backbone.HasOne,
      key: "organization",
      relatedModel: "Organization",
      includeInJSON: true
    }],
    validateAttrs: function (attrs) {
      var errors = [];
      var optionalFields = ["status", "allow_x_adv_pixels", "providerIds"];
      var requiredField = "This is a required field.";
      var validationResult;
      const nameLen = 64;
      var reqFields = {
        organization_id: function () {
          return !attrs.organization_id;
        },
        name: function () {
          return !attrs.name;
        }
      };
      var extraValFields = {
        name: function () {
          if (attrs.name.length > nameLen) {
            return "Name cannot be longer than 64 characters.";
          }
        }
      };
      const validateProvideID = {
        providerIds: function () {
          const pattern = /^[a-z0-9]+$/i;
          const providerIdVal = attrs.providerIds;
          const providerIdLen = 64;
          if (providerIdVal.length && (providerIdVal.length > providerIdLen || !pattern.test(providerIdVal))) {
            return `ID needs to be alphanumeric and under ${providerIdLen} characters`;
          }
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        } else if (validateProvideID[key]) {
          validationResult = validateProvideID[key]();
          if (validationResult) {
            errors.push({
              field: key,
              message: validationResult
            });
          }
        } else if (errors.length === 0 && extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: key,
              message: validationResult
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      const deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var success = options.success || $.noop;
      var self = this;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      validationErrors = self.validateAttrs(rawData);
      const agencyForm = _objectSpread({}, rawData);
      delete agencyForm.providerIds;
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(agencyForm, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    },
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    },
    reset: function () {
      this.attributes = {};
    },
    fixture: "-agency"
  });
  return T1.Models.Agency;
});
